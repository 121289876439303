import React, { Component } from "react";
import { connect } from "react-redux";
import { retrieveBankRegister } from "../../actions/spendMoney";
import { setModule } from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";
import TransferMoneyDataService from "../../services/transfer_money.service";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { parseDate } from "../../helpers/date";
import NumberFormat from "react-number-format";
import { format } from "date-fns";
import Autocomplete from "@mui/material/Autocomplete";
import { AcIndexByValue } from "../../helpers/common";

class BankRegisterList extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			searchAccount: 0,
			searchFrom: format(new Date(), "yyyy-MM-01"),
			searchTo: format(new Date(), "yyyy-MM-dd"),
			count: 0,
			alert: false,
			error: false,
			delID: 0,
			delTitle: "",
			message: false,
			accounts_ready: false,
			accounts: [],
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this._isMounted = true;
			if (this._isMounted) {
				this.props.setModule("Bank Register");
				this.loadSelect();
				this.getRows();
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	loadSelect() {
		TransferMoneyDataService.getAccounts().then((response) => {
			this.setState({
				accounts_ready: true,
				accounts: response.data.Row,
			});
		});
	}

	getRows = () => {
		this.props.showLoading();
		var params = {
			account: this.state.searchAccount,
			from: this.state.searchFrom,
			to: this.state.searchTo,
		};

		this.setState({ message: false }, function () {
			this.props
				.retrieveBankRegister(params)
				.then(() => {
					this.props.hideLoading();
				})
				.catch((e) => {
					this.setState({
						error: true,
						message: e.response.data.error,
					});
					this.props.hideLoading();
				});
		});
	};

	onChangeSearchAccount(e, val) {
		if (val !== null) {
			this.setState({ searchAccount: val.id });
		} else {
			this.setState({ searchAccount: 0 });
		}
	}
	onChangeSearchFrom(e) {
		this.setState({ searchFrom: e.target.value });
	}
	onChangeSearchTo(e) {
		this.setState({ searchTo: e.target.value });
	}

	searchData = (e) => {
		e.preventDefault();
		this.getRows();
	};

	confirmDelete = (id, title) => {
		this.setState({
			alert: true,
			delID: id,
			delTitle: title,
		});
	};

	handleClose = (e) => {
		this.setState({
			alert: false,
			error: false,
		});
	};

	render() {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={(e) => this.searchData(e)}>
							<Typography variant="h5">Search Form</Typography>

							<Grid container spacing={3}>
								<Grid item xs={12} sm={6}>
									{this.state.accounts_ready && (
										<Autocomplete
											options={this.state.accounts}
											getOptionLabel={(option) => option.name}
											fullWidth={true}
											onChange={(e, val) => this.onChangeSearchAccount(e, val)}
											value={AcIndexByValue(
												this.state.accounts,
												this.state.searchAccount
											)}
											sx={{ minWidth: 100 }}
											renderInput={(params) => (
												<TextField
													variant="standard"
													{...params}
													label="Account"
												/>
											)}
										/>
									)}
								</Grid>

								<Grid item xs={12} sm={3}>
									<TextField
										label="From"
										type="date"
										value={this.state.searchFrom}
										onChange={(e) => this.onChangeSearchFrom(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={3}>
									<TextField
										label="To"
										type="date"
										value={this.state.searchTo}
										onChange={(e) => this.onChangeSearchTo(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={12}>
									<Button
										type="submit"
										variant="contained"
										startIcon={<SearchIcon />}
									>
										Search
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>

				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Typography variant="h4" gutterBottom component="div">
							Bank Register
						</Typography>

						<TableContainer component={Paper}>
							<Table sx={{}} size="small">
								<TableHead>
									<TableRow>
										<TableCell width={5}>No.</TableCell>
										<TableCell align="center">Date</TableCell>
										<TableCell align="center">Type</TableCell>
										<TableCell align="center">Code</TableCell>
										<TableCell align="center">Payee</TableCell>
										<TableCell align="center">Account</TableCell>
										<TableCell align="center">
											{this.props.bankRegister.normal_balance === "debit"
												? "Withdrawal"
												: "Charge"}
										</TableCell>
										<TableCell align="center">
											{this.props.bankRegister.normal_balance === "debit"
												? "Deposit"
												: "Payment"}
										</TableCell>
										<TableCell align="center">Balance</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{this.props.bankRegister.Data &&
										this.props.bankRegister.Data.map((row, index) => (
											<TableRow key={index}>
												<TableCell align="right">{1 + index}</TableCell>
												<TableCell align="center">
													{parseDate(row.date)}
												</TableCell>
												<TableCell>{row.typename}</TableCell>
												<TableCell>{row.journal_code}</TableCell>
												<TableCell>{row.memo}</TableCell>
												<TableCell>{row.code}</TableCell>
												<TableCell align="right">
													<NumberFormat
														value={row.withdrawal}
														displayType={"text"}
														thousandSeparator="."
														decimalSeparator=","
														decimalScale={2}
														fixedDecimalScale={true}
														prefix={""}
													/>
												</TableCell>
												<TableCell align="right">
													<NumberFormat
														value={row.deposit}
														displayType={"text"}
														thousandSeparator="."
														decimalSeparator=","
														decimalScale={2}
														fixedDecimalScale={true}
														prefix={""}
													/>
												</TableCell>
												<TableCell align="right">
													<NumberFormat
														value={row.last_balance}
														displayType={"text"}
														thousandSeparator="."
														decimalSeparator=","
														decimalScale={2}
														fixedDecimalScale={true}
														prefix={""}
													/>
												</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>

						<Dialog
							open={this.state.error}
							onClose={this.handleClose}
							aria-labelledby="error-dialog-title"
							aria-describedby="error-dialog-description"
						>
							<DialogTitle id="error-dialog-title">Failed</DialogTitle>
							<DialogContent>
								<DialogContentText id="error-dialog-description">
									{this.state.message}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleClose} autoFocus>
									Close
								</Button>
							</DialogActions>
						</Dialog>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		bankRegister: state.spendMoney,
		common: state.common,
		loading: state.loading,
	};
};

export default connect(mapStateToProps, {
	retrieveBankRegister,
	setModule,
	showLoading,
	hideLoading,
})(BankRegisterList);
