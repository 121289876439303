import React, { Component } from "react";
import { connect } from "react-redux";
import { setModule, setDateTo } from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";

import ReportDataService from "../../services/reports.service";
import CardDataService from "../../services/card.service";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { format } from "date-fns";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
//import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import NumberFormat from "react-number-format";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Autocomplete from "@mui/material/Autocomplete";
import { AcIndexByValue, AllowCompany } from "../../helpers/common";

class standardBalanceSheetReport extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		let dateTo;

		if (this.props.common.dateTo) {
			dateTo = this.props.common.dateTo;
		} else {
			dateTo = format(new Date(), "yyyy-MM-dd");
		}

		this.state = {
			searchTo: dateTo,
			searchIdCompany: this.props.auth.idCompany,
			searchComp: "",
			searchInclude0Balance: "0",
			rowsSort: [],
			rowsSortEquity: [],
			rowsAccounts: [],
			error: false,
			message: false,

			companies_ready: false,
			companies: [],
			netAssets: 0,
			xlsUrl: "https://fasreport.raywhite.co.id/standardbalancesheet",
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.props.setModule("Standard Balance Sheet");

			this.getRows();
			this.loadSelect();
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	getRows = () => {
		this.props.showLoading();
		this.props.setDateTo(this.state.searchTo);
		var params = {
			id_company: this.state.searchIdCompany,
			companies: this.state.searchComp,
			to: this.state.searchTo,
			include0balance: this.state.searchInclude0Balance,
		};

		this.setState({ message: false }, function () {
			ReportDataService.getStandardBalanceSheet(params)
				.then((response) => {
					this.setState({
						rowsSort: response.data.Data3,
						rowsSortEquity: response.data.Data4,
						rowsAccounts: response.data.Data5,
						netAssets: response.data.Data6,
					});
					this.props.hideLoading();
				})
				.catch((e) => {
					//console.log(e);
					this.setState({
						error: true,
						//message: e.response.data.error,
					});
					this.props.hideLoading();
				});
		});
	};

	loadSelect() {
		CardDataService.getCompanies().then((response) => {
			this.setState({
				companies_ready: true,
				companies: response.data.Row,
			});
		});
	}

	onChangeSearchIdCompany(e, val) {
		if (val !== null) {
			this.setState({ searchIdCompany: val.id });
		} else {
			this.setState({ searchIdCompany: 0 });
		}
	}
	onChangeSearchTo(e) {
		this.setState({ searchTo: e.target.value });
	}
	onChangeSearchInclude0Balance(e) {
		let checked = "0";
		if (e.target.checked) {
			checked = "1";
		}
		this.setState({ searchInclude0Balance: checked });
	}

	searchData = (e) => {
		e.preventDefault();
		this.getRows();
	};

	handleClose = (e) => {
		this.setState({
			error: false,
		});
	};

	tableRowSort(rows, rowsAccounts) {
		let arr = [];
		let head = false;
		let name = "";
		let i = 0;

		let prefix = "---> ";
		let newName = "";

		for (var k in rows) {
			//if (rows.hasOwnProperty(k)) {
			if (rowsAccounts.hasOwnProperty(k)) {
				name = rowsAccounts[k].name;
				newName = "";

				if (rowsAccounts[k].header) {
					head = true;
				} else {
					head = false;
				}

				for (i = 1; i < rowsAccounts[k].depth; i++) {
					newName = prefix + newName;
				}

				arr.push(
					<TableRow>
						<TableCell>
							{head ? (
								<>
									{newName}
									<b>{name}</b>
								</>
							) : (
								<>{newName + " " + name}</>
							)}
						</TableCell>

						<TableCell align="right">
							{rowsAccounts[k].depth > 3 && !head && (
								<NumberFormat
									value={rowsAccounts[k].current_balance}
									displayType={"text"}
									thousandSeparator="."
									decimalSeparator=","
									decimalScale={2}
									fixedDecimalScale={true}
									prefix={""}
								/>
							)}
						</TableCell>
						<TableCell align="right">
							{rowsAccounts[k].depth === 3 && !head && (
								<NumberFormat
									value={rowsAccounts[k].current_balance}
									displayType={"text"}
									thousandSeparator="."
									decimalSeparator=","
									decimalScale={2}
									fixedDecimalScale={true}
									prefix={""}
								/>
							)}
						</TableCell>
						<TableCell align="right">
							{rowsAccounts[k].depth === 2 && !head && (
								<NumberFormat
									value={rowsAccounts[k].current_balance}
									displayType={"text"}
									thousandSeparator="."
									decimalSeparator=","
									decimalScale={2}
									fixedDecimalScale={true}
									prefix={""}
								/>
							)}
						</TableCell>
						<TableCell align="right">
							{rowsAccounts[k].depth === 1 && !head && (
								<NumberFormat
									value={rowsAccounts[k].current_balance}
									displayType={"text"}
									thousandSeparator="."
									decimalSeparator=","
									decimalScale={2}
									fixedDecimalScale={true}
									prefix={""}
								/>
							)}
						</TableCell>
					</TableRow>
				);

				if (Object.keys(rows[k]).length > 0) {
					arr.push(this.tableRowSort(rows[k], rowsAccounts));
				}

				if (head) {
					arr.push(
						<TableRow>
							<TableCell>
								{newName}
								<b>Total {name}</b>
							</TableCell>

							<TableCell align="right">
								{rowsAccounts[k].depth > 3 && (
									<NumberFormat
										value={rowsAccounts[k].current_balance}
										displayType={"text"}
										thousandSeparator="."
										decimalSeparator=","
										decimalScale={2}
										fixedDecimalScale={true}
										prefix={""}
									/>
								)}
							</TableCell>
							<TableCell align="right">
								{rowsAccounts[k].depth === 3 && (
									<NumberFormat
										value={rowsAccounts[k].current_balance}
										displayType={"text"}
										thousandSeparator="."
										decimalSeparator=","
										decimalScale={2}
										fixedDecimalScale={true}
										prefix={""}
									/>
								)}
							</TableCell>
							<TableCell align="right">
								{rowsAccounts[k].depth === 2 && (
									<NumberFormat
										value={rowsAccounts[k].current_balance}
										displayType={"text"}
										thousandSeparator="."
										decimalSeparator=","
										decimalScale={2}
										fixedDecimalScale={true}
										prefix={""}
									/>
								)}
							</TableCell>
							<TableCell align="right">
								{rowsAccounts[k].depth === 1 && (
									<NumberFormat
										value={rowsAccounts[k].current_balance}
										displayType={"text"}
										thousandSeparator="."
										decimalSeparator=","
										decimalScale={2}
										fixedDecimalScale={true}
										prefix={""}
									/>
								)}
							</TableCell>
						</TableRow>
					);
				}
			}
		}

		return arr;
	}

	CheckedCompanies(id) {
		const cs = this.state.searchComp.split(",");
		return cs.includes(id.toString());
	}

	onChangeCompanies(e) {
		let cs = this.state.searchComp.split(",");
		let val = e.target.value.toString();

		if (e.target.checked) {
			if (!cs.includes(val)) {
				cs.push(val);
			}
		} else {
			let i = cs.indexOf(val);
			if (i !== -1) {
				cs.splice(i, 1);
			}
		}

		this.setState({ searchComp: cs.join(",").replace(/^,/, "") });
	}

	render() {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={(e) => this.searchData(e)}>
							<Typography variant="h5">Search Form</Typography>

							<Grid container spacing={3}>
								{AllowCompany(this.props.auth.user.usergroup) && (
									<Grid item xs={12} sm={12}>
										{this.state.companies_ready && (
											<Autocomplete
												options={this.state.companies}
												getOptionLabel={(option) => option.name}
												fullWidth={true}
												onChange={(e, val) =>
													this.onChangeSearchIdCompany(e, val)
												}
												value={AcIndexByValue(
													this.state.companies,
													this.state.searchIdCompany
												)}
												sx={{ minWidth: 100 }}
												renderInput={(params) => (
													<TextField
														variant="standard"
														{...params}
														label="Company"
													/>
												)}
											/>
										)}
									</Grid>
								)}

								<Grid item xs={12} sm={3}>
									<TextField
										label="As Of"
										type="date"
										value={this.state.searchTo}
										onChange={(e) => this.onChangeSearchTo(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={9}>
									<FormControlLabel
										control={
											<Checkbox
												checked={
													this.state.searchInclude0Balance === "1" ||
													this.state.searchInclude0Balance === 1
												}
												onChange={(e) => this.onChangeSearchInclude0Balance(e)}
											/>
										}
										label="Include 0 Balance"
									/>
								</Grid>
								{/* {this.state.companies_ready &&
									this.state.companies.length > 1 && (
										<Grid item xs={12} sm={12}>
											<Grid item xs={12} sm={6}>
												<div className="MuiFormLabel-root MuiFormLabel-colorPrimary css-u4tvz2-MuiFormLabel-root">
													Consolidate :
												</div>
											</Grid>
											<Grid container spacing={0}>
												{this.state.companies.map((option, index) => (
													<Grid item xs={12} sm={6}>
														<FormControlLabel
															control={
																<Checkbox
																	checked={this.CheckedCompanies(option.id)}
																	onChange={(e) => this.onChangeCompanies(e)}
																	style={{ padding: 3 }}
																/>
															}
															value={option.id}
															label={
																<Box component="div" fontSize={14}>
																	{option.name}
																</Box>
															}
														/>
													</Grid>
												))}
											</Grid>
										</Grid>
									)} */}

								<Grid item xs={6} sm={6}>
									<Button
										type="submit"
										variant="contained"
										startIcon={<SearchIcon />}
									>
										Search
									</Button>
								</Grid>
								<Grid item xs={6} sm={6} container justifyContent="flex-end">
									<Link
										href={
											this.state.xlsUrl +
											"?id_company=" +
											this.state.searchIdCompany +
											"&companies=" +
											this.state.searchComp +
											"&to=" +
											this.state.searchTo +
											"&include0balance=" +
											this.state.searchInclude0Balance +
											"&token=" +
											this.props.auth.user.token
										}
										underline="always"
									>
										Export
									</Link>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>

				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Typography variant="h4" gutterBottom component="div">
							Standard Balance Sheet
						</Typography>

						<TableContainer component={Paper}>
							<Table sx={{}} size="small">
								<TableBody>
									<>
										{this.tableRowSort(
											this.state.rowsSort,
											this.state.rowsAccounts
										)}
									</>

									<TableRow>
										<TableCell colSpan={5}>&nbsp;</TableCell>
									</TableRow>

									<TableRow>
										<TableCell>
											<Typography
												variant="subtitle2"
												gutterBottom
												component="div"
											>
												Net Assets
											</Typography>
										</TableCell>
										<TableCell colSpan={4} align="right">
											<NumberFormat
												value={this.state.netAssets}
												displayType={"text"}
												thousandSeparator="."
												decimalSeparator=","
												decimalScale={2}
												fixedDecimalScale={true}
												prefix={""}
											/>
										</TableCell>
									</TableRow>

									<TableRow>
										<TableCell colSpan={5}>&nbsp;</TableCell>
									</TableRow>

									<>
										{this.tableRowSort(
											this.state.rowsSortEquity,
											this.state.rowsAccounts
										)}
									</>

									<TableRow>
										<TableCell colSpan={5}>&nbsp;</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>

						<Dialog
							open={this.state.error}
							onClose={this.handleClose}
							aria-labelledby="error-dialog-title"
							aria-describedby="error-dialog-description"
						>
							<DialogTitle id="error-dialog-title">Failed</DialogTitle>
							<DialogContent>
								<DialogContentText id="error-dialog-description">
									{this.state.message}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleClose} autoFocus>
									Close
								</Button>
							</DialogActions>
						</Dialog>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		common: state.common,
		loading: state.loading,
	};
};

export default connect(mapStateToProps, {
	setModule,
	setDateTo,
	showLoading,
	hideLoading,
})(standardBalanceSheetReport);
