import http_fas from "../http-fas";

class PurchaseOrderDataService {
	getAll(params) {
		return http_fas.get("/purchaseorder", { params });
	}

	get(id) {
		return http_fas.get(`/purchaseorder/${id}`);
	}

	create(data) {
		return http_fas.post("/savepurchaseorder", data);
	}

	delete(id) {
		return http_fas.delete(`/removepurchaseorder/${id}`);
	}

	code(id) {
		return http_fas.get(`/purchaseordercode/${id}`);
	}
}

const dataService = new PurchaseOrderDataService();
export default dataService;
