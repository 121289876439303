import React, { Component } from "react";
import { connect } from "react-redux";
import { setModule, setDateFrom, setDateTo } from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";

import ReportDataService from "../../services/reports.service";
import CardDataService from "../../services/card.service";
import JournalDataService from "../../services/journal.service";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { format } from "date-fns";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { parseDate } from "../../helpers/date";
import { linkType } from "../../helpers/common";
import NumberFormat from "react-number-format";
import Link from "@mui/material/Link";
import Autocomplete from "@mui/material/Autocomplete";
import { AcIndexByValue, AllowCompany } from "../../helpers/common";

class FindTransactionsReport extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		let dateFrom, dateTo;

		if (this.props.common.dateFrom) {
			dateFrom = this.props.common.dateFrom;
		} else {
			dateFrom = format(new Date(), "yyyy-MM-01");
		}

		if (this.props.common.dateTo) {
			dateTo = this.props.common.dateTo;
		} else {
			dateTo = format(new Date(), "yyyy-MM-dd");
		}

		this.state = {
			searchFrom: dateFrom,
			searchTo: dateTo,
			searchIdCompany: this.props.auth.idCompany,
			searchIdAccount: 0,
			searchIdCard: 0,
			searchIdJob: 0,
			rows: [],
			error: false,
			message: false,

			companies_ready: false,
			cards_ready: false,
			jobs_ready: false,
			accounts_ready: false,
			companies: [],
			cards: [],
			jobs: [],
			accounts: [],
			xlsUrl: "https://fasreport.raywhite.co.id/findtransaction",
			result: [],
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.props.setModule("Find Transactions");

			this.getRows();
			this.loadSelect();
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	getRows = () => {
		this.props.showLoading();
		this.props.setDateFrom(this.state.searchFrom);
		this.props.setDateTo(this.state.searchTo);
		var params = {
			id_company: this.state.searchIdCompany,
			from: this.state.searchFrom,
			to: this.state.searchTo,
			id_card: this.state.searchIdCard,
			id_job: this.state.searchIdJob,
			id_account: this.state.searchIdAccount,
		};

		this.setState({ message: false }, function () {
			ReportDataService.getFindTransactions(params)
				.then((response) => {
					this.setState({
						rows: response.data.Data,
						result: response.data,
					});
					this.props.hideLoading();
				})
				.catch((e) => {
					this.setState({
						error: true,
						message: e.response.data.error,
					});
					this.props.hideLoading();
				});
		});
	};

	loadSelect() {
		if (AllowCompany(this.props.auth.user.usergroup)) {
			CardDataService.getCompanies().then((response) => {
				this.setState({
					companies_ready: true,
					companies: response.data.Row,
				});
			});
		}
		CardDataService.getCards().then((response) => {
			this.setState({
				cards_ready: true,
				cards: response.data.Row,
			});
		});
		JournalDataService.getJobs().then((response) => {
			this.setState({
				jobs_ready: true,
				jobs: response.data.Row,
			});
		});
		JournalDataService.getAccounts(this.state.searchIdCompany).then(
			(response) => {
				this.setState({
					accounts_ready: true,
					accounts: response.data.Row,
				});
			}
		);
	}

	onChangeSearchIdCompany(e, val) {
		if (val !== null) {
			this.setState({ searchIdCompany: val.id });
		} else {
			this.setState({ searchIdCompany: 0 });
		}

		JournalDataService.getAccounts(val.id).then((response) => {
			this.setState({
				accounts: response.data.Row,
				searchIdAccount: 0,
			});
		});
	}

	onChangeSearchFrom(e) {
		this.setState({ searchFrom: e.target.value });
	}
	onChangeSearchTo(e) {
		this.setState({ searchTo: e.target.value });
	}
	onChangeSearchIdCard(e) {
		this.setState({ searchIdCard: e.target.value });
	}
	onChangeSearchIdJob(e) {
		this.setState({ searchIdJob: e.target.value });
	}
	onChangeSearchIdAccount(e, val) {
		if (val !== null) {
			this.setState({ searchIdAccount: val.id });
		} else {
			this.setState({ searchIdAccount: 0 });
		}
	}

	searchData = (e) => {
		e.preventDefault();
		this.getRows();
	};

	handleClose = (e) => {
		this.setState({
			error: false,
		});
	};

	render() {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={(e) => this.searchData(e)}>
							<Typography variant="h5">Search Form</Typography>

							<Grid container spacing={3}>
								{AllowCompany(this.props.auth.user.usergroup) && (
									<Grid item xs={12} sm={12}>
										{this.state.companies_ready && (
											<Autocomplete
												options={this.state.companies}
												getOptionLabel={(option) => option.name}
												fullWidth={true}
												onChange={(e, val) =>
													this.onChangeSearchIdCompany(e, val)
												}
												value={AcIndexByValue(
													this.state.companies,
													this.state.searchIdCompany
												)}
												sx={{ minWidth: 100 }}
												renderInput={(params) => (
													<TextField
														variant="standard"
														{...params}
														label="Company"
													/>
												)}
											/>
										)}
									</Grid>
								)}

								<Grid item xs={12} sm={3}>
									<TextField
										label="From"
										type="date"
										value={this.state.searchFrom}
										onChange={(e) => this.onChangeSearchFrom(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={3}>
									<TextField
										label="To"
										type="date"
										value={this.state.searchTo}
										onChange={(e) => this.onChangeSearchTo(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>
								<Grid item xs={12} sm={6}></Grid>

								<Grid item xs={12} sm={4}>
									{this.state.accounts_ready && (
										<Autocomplete
											options={this.state.accounts}
											getOptionLabel={(option) => option.name}
											fullWidth={true}
											onChange={(e, val) =>
												this.onChangeSearchIdAccount(e, val)
											}
											value={AcIndexByValue(
												this.state.accounts,
												this.state.searchIdAccount
											)}
											sx={{ minWidth: 100 }}
											renderInput={(params) => (
												<TextField
													variant="standard"
													{...params}
													label="Account"
												/>
											)}
										/>
									)}
								</Grid>

								<Grid item xs={12} sm={4}>
									{this.state.cards_ready && (
										<FormControl
											fullWidth={true}
											variant="standard"
											sx={{ minWidth: 100 }}
										>
											<InputLabel id="cardLabel">Card</InputLabel>
											<Select
												labelId="cardLabel"
												label="Card"
												value={this.state.searchIdCard}
												onChange={(e) => this.onChangeSearchIdCard(e)}
											>
												<MenuItem value="0">
													<em>None</em>
												</MenuItem>
												{this.state.cards.map((option, index) => (
													<MenuItem key={index} value={option.id}>
														{option.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									)}
								</Grid>

								<Grid item xs={12} sm={4}>
									{this.state.jobs_ready && (
										<FormControl
											fullWidth={true}
											variant="standard"
											sx={{ minWidth: 100 }}
										>
											<InputLabel id="jobsLabel">Job</InputLabel>
											<Select
												labelId="jobsLabel"
												label="Job"
												value={this.state.searchIdJob}
												onChange={(e) => this.onChangeSearchIdJob(e)}
											>
												<MenuItem value="0">
													<em>None</em>
												</MenuItem>
												{this.state.jobs.map((option, index) => (
													<MenuItem key={index} value={option.id}>
														{option.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									)}
								</Grid>

								<Grid item xs={6} sm={6}>
									<Button
										type="submit"
										variant="contained"
										startIcon={<SearchIcon />}
									>
										Search
									</Button>
								</Grid>
								<Grid item xs={6} sm={6} container justifyContent="flex-end">
									<Link
										href={
											this.state.xlsUrl +
											"?id_company=" +
											this.state.searchIdCompany +
											"&from=" +
											this.state.searchFrom +
											"&to=" +
											this.state.searchTo +
											"&id_card=" +
											this.state.searchIdCard +
											"&id_account=" +
											this.state.searchIdAccount +
											"&id_job=" +
											this.state.searchIdJob +
											"&token=" +
											this.props.auth.user.token
										}
										underline="always"
									>
										Export
									</Link>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>

				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Typography variant="h4" gutterBottom component="div">
							Find Transactions
						</Typography>

						<TableContainer component={Paper}>
							<Table sx={{}} size="small">
								<TableHead>
									<TableRow>
										<TableCell width={5}>No.</TableCell>
										<TableCell align="center">Src</TableCell>
										<TableCell align="center">Code</TableCell>
										<TableCell align="center">Date</TableCell>
										<TableCell align="center">Account</TableCell>
										<TableCell align="center">Note</TableCell>
										<TableCell align="center">Card</TableCell>
										<TableCell align="center">Debit</TableCell>
										<TableCell align="center">Credit</TableCell>
										<TableCell align="center">Job</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{this.state.rows &&
										this.state.rows.map((row, index) => (
											<TableRow key={index}>
												<TableCell align="right">{1 + index}</TableCell>
												<TableCell>{linkType(row)}</TableCell>
												<TableCell>{row.code}</TableCell>
												<TableCell>{parseDate(row.date)}</TableCell>
												<TableCell>
													{row.account_code} {row.account_name}
												</TableCell>
												<TableCell>{row.note}</TableCell>
												<TableCell>{row.card}</TableCell>
												<TableCell align="right">
													{row.debit !== 0 && (
														<NumberFormat
															value={row.debit}
															displayType={"text"}
															thousandSeparator="."
															decimalSeparator=","
															decimalScale={2}
															fixedDecimalScale={true}
															prefix={""}
														/>
													)}
												</TableCell>
												<TableCell align="right">
													{row.credit !== 0 && (
														<NumberFormat
															value={row.credit}
															displayType={"text"}
															thousandSeparator="."
															decimalSeparator=","
															decimalScale={2}
															fixedDecimalScale={true}
															prefix={""}
														/>
													)}
												</TableCell>
												<TableCell>{row.job}</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>

						{this.state.result.single_account && (
							<TableContainer component={Paper}>
								<Table sx={{}} size="small">
									<TableBody>
										<TableRow colSpan={5}>
											<TableCell sx={{ borderBottom: "none" }}></TableCell>
										</TableRow>
										<TableRow>
											<TableCell align="center" className="tblBold">
												Beginning Balance
											</TableCell>
											<TableCell align="center" className="tblBold">
												Total Debits
											</TableCell>
											<TableCell align="center" className="tblBold">
												Total Credits
											</TableCell>
											<TableCell align="center" className="tblBold">
												Net Change
											</TableCell>
											<TableCell align="center" className="tblBold">
												Ending Balance
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell align="center" className="tblBold">
												<NumberFormat
													value={this.state.result.beginning_balance}
													displayType={"text"}
													thousandSeparator="."
													decimalSeparator=","
													decimalScale={2}
													fixedDecimalScale={true}
												/>
											</TableCell>
											<TableCell align="center" className="tblBold">
												<NumberFormat
													value={this.state.result.total_debits}
													displayType={"text"}
													thousandSeparator="."
													decimalSeparator=","
													decimalScale={2}
													fixedDecimalScale={true}
												/>
											</TableCell>
											<TableCell align="center" className="tblBold">
												<NumberFormat
													value={this.state.result.total_credits}
													displayType={"text"}
													thousandSeparator="."
													decimalSeparator=","
													decimalScale={2}
													fixedDecimalScale={true}
												/>
											</TableCell>
											<TableCell align="center" className="tblBold">
												<NumberFormat
													value={this.state.result.net_change}
													displayType={"text"}
													thousandSeparator="."
													decimalSeparator=","
													decimalScale={2}
													fixedDecimalScale={true}
												/>
											</TableCell>
											<TableCell align="center" className="tblBold">
												<NumberFormat
													value={this.state.result.ending_balance}
													displayType={"text"}
													thousandSeparator="."
													decimalSeparator=","
													decimalScale={2}
													fixedDecimalScale={true}
												/>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						)}

						<Dialog
							open={this.state.error}
							onClose={this.handleClose}
							aria-labelledby="error-dialog-title"
							aria-describedby="error-dialog-description"
						>
							<DialogTitle id="error-dialog-title">Failed</DialogTitle>
							<DialogContent>
								<DialogContentText id="error-dialog-description">
									{this.state.message}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleClose} autoFocus>
									Close
								</Button>
							</DialogActions>
						</Dialog>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		common: state.common,
		loading: state.loading,
	};
};

export default connect(mapStateToProps, {
	setModule,
	setDateFrom,
	setDateTo,
	showLoading,
	hideLoading,
})(FindTransactionsReport);
