import http_fas from "../http-fas";

class PayBillDataService {
	getAll(params) {
		return http_fas.get("/paybill", { params });
	}

	get(id) {
		return http_fas.get(`/paybill/${id}`);
	}

	create(data) {
		return http_fas.post("/savepaybill", data);
	}

	delete(id) {
		return http_fas.delete(`/removepaybill/${id}`);
	}

	code(id) {
		return http_fas.get(`/paybillcode/${id}`);
	}
}

const dataService = new PayBillDataService();
export default dataService;
