import axios from "axios";

const user = JSON.parse(localStorage.getItem("user"));
const token = user && user.token ? "Bearer " + user.token : "";

const http_fas = axios.create({
	//https://fasapis.raywhite.co.id
	//https://fastest.raywhite.co.id
	//https://fasapidev.raywhite.co.id

	baseURL: "https://fasapis.raywhite.co.id",
	headers: {
		"Content-type": "application/json",
		Authorization: token,
	},
});

export default http_fas;
