import http_fas from "../http-fas";

class AccountDataService {
	getAll(params) {
		return http_fas.get("/accounts", { params });
	}

	get(id, params) {
		return http_fas.get(`/accounts/${id}`, { params });
	}

	create(data) {
		return http_fas.post("/saveaccount", data);
	}

	delete(id, params) {
		return http_fas.delete(`/removeaccount/${id}`, { params });
	}

	getParent(params) {
		return http_fas.get(`master/account_parent`, { params });
	}
	getType() {
		return http_fas.get(`master/account_type`);
	}
	getTaxes() {
		return http_fas.get(`master/tax`);
	}
	getCashFlow() {
		return http_fas.get(`master/cashflow`);
	}
	getPeriod(params) {
		return http_fas.get(`master/period`, { params });
	}
	getLinkedAccounts(id) {
		return http_fas.get(`/linkedaccounts/${id}`);
	}
	updateLinkedAccounts(data) {
		return http_fas.post(`savelinkedaccounts`, data);
	}
	getCurrentBalance(id, idPeriod) {
		return http_fas.get(`/accountbalance/${id}/${idPeriod}`);
	}
}

const dataService = new AccountDataService();
export default dataService;
