import React, { Component } from "react";
import { connect } from "react-redux";
import { createTransferMoney } from "../../actions/transfer_money";
import { setModule, setAccounts } from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";
import TransferMoneyDataService from "../../services/transfer_money.service";
import CardDataService from "../../services/card.service";
import AccountDataService from "../../services/account.service";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { format } from "date-fns";
import Divider from "@mui/material/Divider";
import NumberFormat from "react-number-format";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
	AcIndexByValue,
	parseNumber,
	AllowCompany,
} from "../../helpers/common";

class AddTransferMoney extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		let from;
		if (this.props.common.account) {
			from = this.props.common.account;
		} else {
			from = 0;
		}

		this.state = {
			id: null,
			id_company: this.props.auth.idCompany,
			code: "",
			date: format(new Date(), "yyyy-MM-dd"),
			note: "",
			from: from,
			to: 0,
			amount: "",

			error: false,
			message: false,

			companies_ready: false,
			accounts_ready: false,

			companies: [],
			accounts: [],

			recurring: false,
			recurring_name: "",
			balanceFrom: 0,
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.props.setModule("Transfer Money");
			if (typeof this.props.match.params.id !== "undefined") {
				let id = parseNumber(this.props.match.params.id);
				let id_recurring = parseNumber(this.props.match.params.id_recurring);

				if (id > 0) {
					this.loadCurrentRow();
				}

				if (id_recurring > 0) {
					this.loadRecurringRow(id_recurring);
				}

				this.loadSelect();
			} else {
				this.newData();
				this.loadSelect();
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate() {
		if (typeof this.props.match.params.id === "undefined") {
			if (this.state.id !== null) {
				this.newData();
			}
		}
	}

	loadSelect() {
		CardDataService.getCompanies().then((response) => {
			this.setState({
				companies_ready: true,
				companies: response.data.Row,
			});
		});
		TransferMoneyDataService.getAccounts().then((response) => {
			this.setState({
				accounts_ready: true,
				accounts: response.data.Row,
			});
		});
	}

	loadCurrentRow() {
		this.props.showLoading();

		TransferMoneyDataService.get(this.props.match.params.id)
			.then((response) => {
				this.setState(
					{
						id: response.data.Row.id,
						id_company: response.data.Row.id_company,
						code: response.data.Row.code,
						date: response.data.Row.date,
						note: response.data.Row.note,
						from: response.data.Row.from,
						to: response.data.Row.to,
						amount: response.data.Row.amount,
					},
					function () {
						this.updateBalanceFrom(
							response.data.Row.from,
							this.props.auth.user.id_period
						);
					}
				);

				this.props.hideLoading();
			})
			.catch((e) => {
				this.setState({ message: e.response.data.error });
				this.props.hideLoading();
			});
	}

	loadRecurringRow(id_recurring) {
		this.props.showLoading();

		TransferMoneyDataService.code(this.props.auth.idCompany).then((resc) => {
			TransferMoneyDataService.get(id_recurring)
				.then((response) => {
					this.setState(
						{
							id: null,
							id_company: this.props.auth.idCompany,
							code: resc.data,
							date: format(new Date(), "yyyy-MM-dd"),
							note: response.data.Row.note,
							from: response.data.Row.from,
							to: response.data.Row.to,
							amount: response.data.Row.amount,
						},
						function () {
							this.updateBalanceFrom(
								response.data.Row.from,
								this.props.auth.user.id_period
							);
						}
					);

					this.props.hideLoading();
				})
				.catch((e) => {
					this.setState({ message: e.response.data.error });
					this.props.hideLoading();
				});
		});
	}

	newData() {
		TransferMoneyDataService.code(this.props.auth.idCompany).then(
			(response) => {
				this.setState(
					{
						id: null,
						id_company: this.props.auth.idCompany,
						code: response.data,
						date: format(new Date(), "yyyy-MM-dd"),
						note: "",
						to: 0,
						amount: "",
						recurring: false,
						recurring_name: "",

						message: false,
					},
					function () {
						if (this.state.from === 0) {
							TransferMoneyDataService.getPayingBills().then((res) => {
								this.setState({ from: res.data }, function () {
									this.updateBalanceFrom(
										res.data,
										this.props.auth.user.id_period
									);
								});
							});
						} else {
							this.updateBalanceFrom(
								this.state.from,
								this.props.auth.user.id_period
							);
						}
						this.props.hideLoading();
					}
				);
			}
		);
	}

	onChangeIdCompany(e) {
		this.setState({ id_company: e.target.value });
	}
	onChangeCode(e) {
		this.setState({ code: e.target.value });
	}
	onChangeDate(e) {
		this.setState({ date: e.target.value });
	}
	onChangeNote(e) {
		this.setState({ note: e.target.value });
	}
	onChangeFrom(e, val) {
		if (val !== null) {
			this.setState({ from: val.id });
			this.props.setAccounts(val.id);
			this.updateBalanceFrom(val.id, this.props.auth.user.id_period);
		}
	}
	updateBalanceFrom(id, idParent) {
		AccountDataService.getCurrentBalance(id, idParent).then((response) => {
			this.setState({ balanceFrom: response.data.balance });
		});
	}
	onChangeTo(e, val) {
		if (val !== null) {
			this.setState({ to: val.id });
		}
	}
	onChangeAmount(e) {
		this.setState({ amount: e.target.value });
	}
	onEnterAmount(e) {
		if (e.key === "Enter") {
			this.setState({ amount: e.target.value });
		}
	}
	onChangeRecurring(e) {
		this.setState({ recurring: e.target.checked });
	}
	onChangeRecurringName(e) {
		this.setState({ recurring_name: e.target.value });
	}

	alertClose = () => {
		this.setState({ error: false });
	};

	saveTransferMoney(e) {
		e.preventDefault();
		this.props.showLoading();

		this.setState({
			message: false,
		});

		this.props
			.createTransferMoney(
				this.state.id,
				this.state.id_company,
				this.state.code,
				this.state.date,
				this.state.note,
				this.state.from,
				this.state.to,
				this.state.amount,
				this.state.recurring,
				this.state.recurring_name
			)
			.then((data) => {
				this.newData();
				// this.props.history.push("/transfermoney/form");
			})
			.catch((e) => {
				let message = "";
				if (e.response === undefined) {
					message = "Request time out";
				} else {
					message = e.response.data.error;
				}

				this.setState({
					message: message,
					error: true,
				});
				this.props.hideLoading();
			});
	}

	render() {
		return (
			<Box component="form" onSubmit={(e) => this.saveTransferMoney(e)}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
							<Typography variant="h5">Transfer Money Form</Typography>

							<Grid container spacing={3}>
								<Grid item xs={12} sm={12}>
									<Dialog
										open={this.state.error}
										onClose={this.alertClose}
										aria-labelledby="error-dialog-title"
										aria-describedby="error-dialog-description"
									>
										<DialogTitle id="error-dialog-title">Failed</DialogTitle>
										<DialogContent>
											<DialogContentText id="error-dialog-description">
												{this.state.message}
											</DialogContentText>
										</DialogContent>
										<DialogActions>
											<Button onClick={this.alertClose} autoFocus>
												Close
											</Button>
										</DialogActions>
									</Dialog>
								</Grid>

								{AllowCompany(this.props.auth.user.usergroup) && (
									<Grid item xs={12} sm={12}>
										{this.state.companies_ready && (
											<FormControl
												fullWidth={true}
												variant="standard"
												sx={{ minWidth: 100 }}
											>
												<InputLabel id="idCompany">Company</InputLabel>
												<Select
													labelId="idCompany"
													label="Company"
													value={this.state.id_company}
													onChange={(e) => this.onChangeIdCompany(e)}
												>
													{this.state.companies.map((option, index) => (
														<MenuItem key={index} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										)}
									</Grid>
								)}

								<Grid item xs={12} sm={6}>
									<TextField
										label="Code"
										value={this.state.code}
										onChange={(e) => this.onChangeCode(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										label="Date"
										type="date"
										value={this.state.date}
										onChange={(e) => this.onChangeDate(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<Grid container spacing={0}>
										<Grid item xs={8}>
											{this.state.accounts_ready && (
												<Autocomplete
													options={this.state.accounts}
													getOptionLabel={(option) => option.name}
													fullWidth={true}
													onChange={(e, val) => this.onChangeFrom(e, val)}
													value={AcIndexByValue(
														this.state.accounts,
														this.state.from
													)}
													sx={{ minWidth: 100 }}
													renderInput={(params) => (
														<TextField
															variant="standard"
															{...params}
															label="From"
														/>
													)}
												/>
											)}
										</Grid>
										<Grid
											item
											xs={4}
											container
											direction="row"
											justify="flex-end"
											alignItems="center"
										>
											<Typography
												variant="caption"
												display="block"
												gutterBottom
											>
												Balance :
												<NumberFormat
													label="Balance"
													value={this.state.balanceFrom}
													thousandSeparator="."
													decimalSeparator=","
													decimalScale={2}
													fixedDecimalScale={true}
													displayType={"text"}
												/>
											</Typography>
										</Grid>
									</Grid>
								</Grid>

								<Grid item xs={12} sm={6}>
									{this.state.accounts_ready && (
										<Autocomplete
											options={this.state.accounts}
											getOptionLabel={(option) => option.name}
											fullWidth={true}
											onChange={(e, val) => this.onChangeTo(e, val)}
											value={AcIndexByValue(this.state.accounts, this.state.to)}
											sx={{ minWidth: 100 }}
											renderInput={(params) => (
												<TextField variant="standard" {...params} label="To" />
											)}
										/>
									)}
								</Grid>

								<Grid item xs={12} sm={6}>
									<FormControl
										fullWidth={true}
										variant="standard"
										sx={{ minWidth: 100 }}
									>
										<NumberFormat
											customInput={TextField}
											label="Amount"
											value={this.state.amount}
											fullWidth
											variant="standard"
											thousandSeparator="."
											decimalSeparator=","
											decimalScale={2}
											fixedDecimalScale={true}
											autoComplete="off"
											onBlur={(e) => this.onChangeAmount(e)}
											onKeyDown={(e) => this.onEnterAmount(e)}
										/>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										label="Note"
										fullWidth
										multiline
										rows={2}
										value={this.state.note}
										onChange={(e) => this.onChangeNote(e)}
									/>
								</Grid>

								<Grid item xs={12} sm={12}>
									<FormControlLabel
										control={
											<Checkbox
												checked={this.state.recurring === true}
												onChange={(e) => this.onChangeRecurring(e)}
											/>
										}
										label="Save as Recurring"
									/>
									{this.state.recurring && (
										<TextField
											label="Recurring Name"
											value={this.state.recurring_name}
											onChange={(e) => this.onChangeRecurringName(e)}
											fullWidth
											variant="standard"
										/>
									)}
								</Grid>

								<Grid item xs={12} sm={12}>
									<Divider />
								</Grid>

								<Grid item xs={12} sm={12}>
									<Button
										variant="contained"
										component={Link}
										to="/transfermoney/form"
										startIcon={<ArrowBackIcon />}
										sx={{ mr: 1 }}
									>
										Cancel
									</Button>
									<Button
										type="submit"
										variant="contained"
										disabled={this.props.loading.loading}
										startIcon={<SaveIcon />}
									>
										{this.props.loading.loading && (
											<span>
												<span className="spinner-border spinner-border-sm"></span>
												&nbsp;
											</span>
										)}
										<span>
											{" "}
											{this.props.loading.loading ? "Please Wait" : "Save"}
										</span>
									</Button>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</Box>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		loading: state.loading,
		common: state.common,
	};
};

export default connect(mapStateToProps, {
	createTransferMoney,
	showLoading,
	hideLoading,
	setModule,
	setAccounts,
})(AddTransferMoney);
