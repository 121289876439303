import * as React from "react";
import { Link } from "react-router-dom";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";

import ListItem from "@mui/material/ListItem";

import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CalculateIcon from "@mui/icons-material/Calculate";
import GroupIcon from "@mui/icons-material/Group";
import BalanceIcon from "@mui/icons-material/Balance";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import PaymentIcon from "@mui/icons-material/Payment";
import AddCardIcon from "@mui/icons-material/AddCard";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ListIcon from "@mui/icons-material/List";
import SearchIcon from "@mui/icons-material/Search";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ListAltIcon from "@mui/icons-material/ListAlt";
import BarChartIcon from "@mui/icons-material/BarChart";
import Divider from "@mui/material/Divider";

const MenuList = (props) => {
	const module = props.module;

	return (
		<React.Fragment>
			<ListItemButton selected={module === "Dashboard"} component={Link} to="/">
				<ListItemIcon>
					<DashboardIcon />
				</ListItemIcon>
				<ListItemText primary="Dashboard" />
			</ListItemButton>

			<Divider />

			<ListSubheader sx={{ pb: 0, height: "32px" }} component="div">
				Account
			</ListSubheader>

			<ListItemButton
				selected={module === "Account"}
				component={Link}
				to="/account/main"
			>
				<ListItemIcon>
					<AccountBalanceIcon />
				</ListItemIcon>
				<ListItemText primary="Account List" />
			</ListItemButton>
			<ListItemButton
				selected={module === "Transfer Money"}
				component={Link}
				to="/addtransfermoney"
			>
				<ListItemIcon>
					<MoveDownIcon />
				</ListItemIcon>
				<ListItemText primary="Transfer Money" />
			</ListItemButton>
			<ListItemButton
				selected={module === "Journal"}
				component={Link}
				to="/addjournal"
			>
				<ListItemIcon>
					<BalanceIcon />
				</ListItemIcon>
				<ListItemText primary="Journal" />
			</ListItemButton>

			<Divider />

			<ListSubheader sx={{ pb: 0, height: "32px" }} component="div">
				Banking
			</ListSubheader>
			<ListItemButton
				selected={module === "Spend Money"}
				component={Link}
				to="/addspendmoney"
			>
				<ListItemIcon>
					<ShoppingCartIcon />
				</ListItemIcon>
				<ListItemText primary="Spend Money" />
			</ListItemButton>
			<ListItemButton
				selected={module === "Bank Register"}
				component={Link}
				to="/bankregister/main"
			>
				<ListItemIcon>
					<ListIcon />
				</ListItemIcon>
				<ListItemText primary="Bank Register" />
			</ListItemButton>
			<ListItemButton
				selected={module === "Receive Money"}
				component={Link}
				to="/addreceivemoney"
			>
				<ListItemIcon>
					<AddCardIcon />
				</ListItemIcon>
				<ListItemText primary="Receive Money" />
			</ListItemButton>

			<Divider />

			<ListSubheader sx={{ pb: 0, height: "32px" }} component="div">
				Sales
			</ListSubheader>
			<ListItemButton
				selected={module === "Sales Register"}
				component={Link}
				to="/salesregister/main"
			>
				<ListItemIcon>
					<ListIcon />
				</ListItemIcon>
				<ListItemText primary="Sales Register" />
			</ListItemButton>

			<ListItemButton
				selected={module === "Order"}
				component={Link}
				to="/addorder"
			>
				<ListItemIcon>
					<ReceiptLongIcon />
				</ListItemIcon>
				<ListItemText primary="Order" />
			</ListItemButton>
			<ListItemButton
				selected={module === "Invoice"}
				component={Link}
				to="/addinvoice"
			>
				<ListItemIcon>
					<ReceiptIcon />
				</ListItemIcon>
				<ListItemText primary="Invoice" />
			</ListItemButton>
			<ListItemButton
				selected={module === "Invoice"}
				component={Link}
				to="/invoice/main"
			>
				<ListItemIcon>
					<ReceiptIcon />
				</ListItemIcon>
				<ListItemText primary="Receive Payment" />
			</ListItemButton>
			<ListItemButton
				selected={module === "Receive Payment"}
				component={Link}
				to="/receivepayment/main"
			>
				<ListItemIcon>
					<AddCardIcon />
				</ListItemIcon>
				<ListItemText primary="Invoice Paid" />
			</ListItemButton>

			<Divider />

			<ListSubheader sx={{ pb: 0, height: "32px" }} component="div">
				Purchases
			</ListSubheader>

			<ListItemButton
				selected={module === "Purchases Register"}
				component={Link}
				to="/purchasesregister/main"
			>
				<ListItemIcon>
					<ListIcon />
				</ListItemIcon>
				<ListItemText primary="Purchases Register" />
			</ListItemButton>

			<ListItemButton
				selected={module === "Purchase Order"}
				component={Link}
				to="/addpurchaseorder"
			>
				<ListItemIcon>
					<ReceiptLongIcon />
				</ListItemIcon>
				<ListItemText primary="Order" />
			</ListItemButton>
			<ListItemButton
				selected={module === "Bill"}
				component={Link}
				to="/addbill"
			>
				<ListItemIcon>
					<ReceiptIcon />
				</ListItemIcon>
				<ListItemText primary="Bill" />
			</ListItemButton>
			<ListItemButton
				selected={module === "Bill"}
				component={Link}
				to="/bill/main"
			>
				<ListItemIcon>
					<ReceiptIcon />
				</ListItemIcon>
				<ListItemText primary="Receive Bill" />
			</ListItemButton>
			<ListItemButton
				selected={module === "Pay Bill"}
				component={Link}
				to="/paybill/main"
			>
				<ListItemIcon>
					<AddCardIcon />
				</ListItemIcon>
				<ListItemText primary="Payed Bill" />
			</ListItemButton>

			<Divider />

			<ListSubheader sx={{ pb: 0, height: "32px" }} component="div">
				Report
			</ListSubheader>

			<ListItemButton
				selected={module === "To Do List"}
				component={Link}
				to="/todolist"
			>
				<ListItemIcon>
					<BalanceIcon />
				</ListItemIcon>
				<ListItemText primary="To Do List" />
			</ListItemButton>

			<ListItemButton
				selected={module === "Transaction Journal"}
				component={Link}
				to="/transactionjournal"
			>
				<ListItemIcon>
					<BalanceIcon />
				</ListItemIcon>
				<ListItemText primary="Transaction Journal" />
			</ListItemButton>

			<ListItemButton
				selected={module === "Find Transactions"}
				component={Link}
				to="/findtransactions"
			>
				<ListItemIcon>
					<SearchIcon />
				</ListItemIcon>
				<ListItemText primary="Find Transactions" />
			</ListItemButton>
			<ListItemButton
				selected={module === "General Ledger Detail"}
				component={Link}
				to="/generalledgerdetail"
			>
				<ListItemIcon>
					<AccountTreeIcon />
				</ListItemIcon>
				<ListItemText primary="General Ledger Detail" />
			</ListItemButton>
			<ListItemButton
				selected={module === "Standard Balance Sheet"}
				component={Link}
				to="/standardbalancesheet"
			>
				<ListItemIcon>
					<ListAltIcon />
				</ListItemIcon>
				<ListItemText primary="Standard Balance Sheet" />
			</ListItemButton>

			<ListItemButton
				selected={module === "BS Consolidate"}
				component={Link}
				to="/standardbalancesheetconsolidate"
			>
				<ListItemIcon>
					<ListAltIcon />
				</ListItemIcon>
				<ListItemText primary="BS Consolidate" />
			</ListItemButton>

			<ListItemButton
				selected={module === "Profit Loss Year to Date"}
				component={Link}
				to="/profitlossytd"
			>
				<ListItemIcon>
					<BarChartIcon />
				</ListItemIcon>
				<ListItemText primary="Profit Loss YTD" />
			</ListItemButton>
			<ListItemButton
				selected={module === "Profit Loss Year to Date Consolidate"}
				component={Link}
				to="/profitlossytdconsolidate"
			>
				<ListItemIcon>
					<BarChartIcon />
				</ListItemIcon>
				<ListItemText primary="Profit Loss YTD Cons.." />
			</ListItemButton>

			<ListItemButton
				selected={module === "Job Profit Loss"}
				component={Link}
				to="/jobprofitloss"
			>
				<ListItemIcon>
					<BarChartIcon />
				</ListItemIcon>
				<ListItemText primary="Job Profit Loss" />
			</ListItemButton>

			<ListItemButton
				selected={module === "Trial Balance"}
				component={Link}
				to="/trialbalance"
			>
				<ListItemIcon>
					<ListAltIcon />
				</ListItemIcon>
				<ListItemText primary="Trial Balance" />
			</ListItemButton>

			<ListItemButton
				selected={module === "Statement of Cash Flow"}
				component={Link}
				to="/statementofcashflow"
			>
				<ListItemIcon>
					<ListAltIcon />
				</ListItemIcon>
				<ListItemText primary="Statement of Cash Flow" />
			</ListItemButton>

			<ListItemButton
				selected={module === "Cash Flow Analysis"}
				component={Link}
				to="/cashflowanalysis"
			>
				<ListItemIcon>
					<ListAltIcon />
				</ListItemIcon>
				<ListItemText primary="Cash Flow Analysis" />
			</ListItemButton>

			<Divider />

			<ListSubheader sx={{ pb: 0, height: "32px" }} component="div">
				Setting
			</ListSubheader>
			<ListItemButton
				selected={module === "Card"}
				component={Link}
				to="/card/main"
			>
				<ListItemIcon>
					<PeopleIcon />
				</ListItemIcon>
				<ListItemText primary="Card" />
			</ListItemButton>
			<ListItemButton
				selected={module === "Job"}
				component={Link}
				to="/job/main"
			>
				<ListItemIcon>
					<AssignmentIcon />
				</ListItemIcon>
				<ListItemText primary="Job" />
			</ListItemButton>
			<ListItemButton
				selected={module === "Tax"}
				component={Link}
				to="/tax/main"
			>
				<ListItemIcon>
					<CalculateIcon />
				</ListItemIcon>
				<ListItemText primary="Tax" />
			</ListItemButton>
			<ListItemButton
				selected={module === "Payment Method"}
				component={Link}
				to="/paymentmethod/main"
			>
				<ListItemIcon>
					<PaymentIcon />
				</ListItemIcon>
				<ListItemText primary="Payment Method" />
			</ListItemButton>

			<ListItemButton
				selected={module === "Linked Accounts"}
				component={Link}
				to="/linkedaccounts"
			>
				<ListItemIcon>
					<AccountCircleIcon />
				</ListItemIcon>
				<ListItemText primary="Linked Accounts" />
			</ListItemButton>

			<Divider />

			{props.usergroup === "administrator" && (
				<>
					<ListSubheader sx={{ pb: 0, height: "32px" }} component="div">
						Administrator
					</ListSubheader>
					<ListItemButton
						selected={module === "Company"}
						component={Link}
						to="/company/main"
					>
						<ListItemIcon>
							<SupervisedUserCircleIcon />
						</ListItemIcon>
						<ListItemText primary="Company" />
					</ListItemButton>
					<ListItemButton
						selected={module === "User"}
						component={Link}
						to="/user/main"
					>
						<ListItemIcon>
							<GroupIcon />
						</ListItemIcon>
						<ListItemText primary="User" />
					</ListItemButton>

					<ListItemButton
						selected={module === "Close Period"}
						component={Link}
						to="/closeperiod"
					>
						<ListItemIcon>
							<SupervisedUserCircleIcon />
						</ListItemIcon>
						<ListItemText primary="Close Period" />
					</ListItemButton>

					<Divider />
				</>
			)}

			{/* <ListSubheader sx={{ pb: 0, height: "32px" }} component="div">
				Transaction List
			</ListSubheader>
			<ListItemButton
				selected={module === "Transfer Money"}
				component={Link}
				to="/transfermoney/main"
			>
				<ListItemIcon>
					<MoveDownIcon />
				</ListItemIcon>
				<ListItemText primary="Transfer Money" />
			</ListItemButton>
			<ListItemButton
				selected={module === "Journal"}
				component={Link}
				to="/journal/main"
			>
				<ListItemIcon>
					<BalanceIcon />
				</ListItemIcon>
				<ListItemText primary="Journal" />
			</ListItemButton>
			<ListItemButton
				selected={module === "Spend Money"}
				component={Link}
				to="/spendmoney/main"
			>
				<ListItemIcon>
					<ShoppingCartIcon />
				</ListItemIcon>
				<ListItemText primary="Spend Money" />
			</ListItemButton>
			<ListItemButton
				selected={module === "Receive Money"}
				component={Link}
				to="/receivemoney/main"
			>
				<ListItemIcon>
					<AddCardIcon />
				</ListItemIcon>
				<ListItemText primary="Receive Money" />
			</ListItemButton>
			<ListItemButton
				selected={module === "Order"}
				component={Link}
				to="/order/main"
			>
				<ListItemIcon>
					<ReceiptLongIcon />
				</ListItemIcon>
				<ListItemText primary="Order" />
			</ListItemButton>
			<ListItemButton
				selected={module === "Invoice"}
				component={Link}
				to="/invoice/main"
			>
				<ListItemIcon>
					<ReceiptIcon />
				</ListItemIcon>
				<ListItemText primary="Invoice" />
			</ListItemButton>

			<Divider /> */}

			<ListSubheader sx={{ pb: 0, height: "32px" }} component="div">
				Profile
			</ListSubheader>
			<ListItemButton
				selected={module === "My Profile"}
				component={Link}
				to="/profile"
			>
				<ListItemIcon>
					<AccountCircleIcon />
				</ListItemIcon>
				<ListItemText primary="My Profile" />
			</ListItemButton>
			<ListItem
				sx={{ pb: 3 }}
				button
				component={Link}
				to="/login"
				onClick={props.logOut}
			>
				<ListItemIcon>
					<LogoutIcon />
				</ListItemIcon>
				<ListItemText primary=" Logout" />
			</ListItem>
		</React.Fragment>
	);
};
export default MenuList;
