import http_fas from "../http-fas";

class JobDataService {
	getAll(params) {
		return http_fas.get("/jobs", { params });
	}

	get(id) {
		return http_fas.get(`/jobs/${id}`);
	}

	create(data) {
		return http_fas.post("/savejob", data);
	}

	delete(id) {
		return http_fas.delete(`/removejob/${id}`);
	}
}

const dataService = new JobDataService();
export default dataService;
