import React, { Component } from "react";
import { connect } from "react-redux";
import { setModule, setYear, setMonth } from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";

import ReportDataService from "../../services/reports.service";
import CardDataService from "../../services/card.service";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { format } from "date-fns";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import NumberFormat from "react-number-format";
import Link from "@mui/material/Link";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import { AllowCompany, AcIndexByValue } from "../../helpers/common";

class TrialBalanceReport extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		let dateYear, dateMonth;

		if (this.props.common.dateYear) {
			dateYear = this.props.common.dateYear;
		} else {
			dateYear = format(new Date(), "yyyy");
		}

		if (this.props.common.dateMonth) {
			dateMonth = this.props.common.dateMonth;
		} else {
			dateMonth = format(new Date(), "MM");
		}

		this.state = {
			searchIdCompany: this.props.auth.idCompany,
			searchComp: "",
			searchMonth: dateMonth,
			searchYear: dateYear,
			searchInclude0Balance: "0",
			rows: [],
			tvd: 0,
			tyd: 0,
			tvc: 0,
			tyc: 0,
			error: false,
			message: false,

			companies_ready: false,
			companies: [],

			months: ReportDataService.getMonths(1),
			years: ReportDataService.getYears(),
			xlsUrl: "https://fasreport.raywhite.co.id/trialbalance",
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.props.setModule("Trial Balance");

			this.getRows();
			this.loadSelect();
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	getRows = () => {
		this.props.showLoading();
		this.props.setMonth(this.state.searchMonth);
		this.props.setYear(this.state.searchYear);
		var params = {
			id_company: this.state.searchIdCompany,
			companies: this.state.searchComp,
			year: this.state.searchYear,
			month: this.state.searchMonth,
			include0balance: this.state.searchInclude0Balance,
		};

		this.setState({ message: false }, function () {
			ReportDataService.getTrialBalance(params)
				.then((response) => {
					this.setState({
						rows: response.data.data,
						tvd: response.data.total_val_debit,
						tyd: response.data.total_ytd_debit,
						tvc: response.data.total_val_credit,
						tyc: response.data.total_ytd_credit,
					});
					this.props.hideLoading();
				})
				.catch((e) => {
					this.setState({
						error: true,
						message: e.response.data.error,
					});
					this.props.hideLoading();
				});
		});
	};

	loadSelect() {
		CardDataService.getCompanies().then((response) => {
			this.setState({
				companies_ready: true,
				companies: response.data.Row,
			});
		});
	}

	onChangeSearchIdCompany(e, val) {
		if (val !== null) {
			this.setState({ searchIdCompany: val.id });
		} else {
			this.setState({ searchIdCompany: 0 });
		}
	}
	onChangeSearchMonth(e) {
		this.setState({ searchMonth: e.target.value });
	}
	onChangeSearchYear(e) {
		this.setState({ searchYear: e.target.value });
	}
	onChangeSearchInclude0Balance(e) {
		let checked = "0";
		if (e.target.checked) {
			checked = "1";
		}
		this.setState({ searchInclude0Balance: checked });
	}

	searchData = (e) => {
		e.preventDefault();
		this.getRows();
	};

	handleClose = (e) => {
		this.setState({
			error: false,
		});
	};

	CheckedCompanies(id) {
		const cs = this.state.searchComp.split(",");
		return cs.includes(id.toString());
	}

	onChangeCompanies(e) {
		let cs = this.state.searchComp.split(",");
		let val = e.target.value.toString();

		if (e.target.checked) {
			if (!cs.includes(val)) {
				cs.push(val);
			}
		} else {
			let i = cs.indexOf(val);
			if (i !== -1) {
				cs.splice(i, 1);
			}
		}

		this.setState({ searchComp: cs.join(",").replace(/^,/, "") });
	}

	render() {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={(e) => this.searchData(e)}>
							<Typography variant="h5">Search Form</Typography>

							<Grid container spacing={3}>
								{AllowCompany(this.props.auth.user.usergroup) && (
									<Grid item xs={12} sm={12}>
										{this.state.companies_ready && (
											<Autocomplete
												options={this.state.companies}
												getOptionLabel={(option) => option.name}
												fullWidth={true}
												onChange={(e, val) =>
													this.onChangeSearchIdCompany(e, val)
												}
												value={AcIndexByValue(
													this.state.companies,
													this.state.searchIdCompany
												)}
												sx={{ minWidth: 100 }}
												renderInput={(params) => (
													<TextField
														variant="standard"
														{...params}
														label="Company"
													/>
												)}
											/>
										)}
									</Grid>
								)}

								<Grid item xs={12} sm={3}>
									<FormControl
										fullWidth={true}
										variant="standard"
										sx={{ minWidth: 100 }}
									>
										<InputLabel id="monthLabel">Periode</InputLabel>
										<Select
											labelId="monthLabel"
											label="Periode"
											value={this.state.searchMonth}
											onChange={(e) => this.onChangeSearchMonth(e)}
										>
											<MenuItem value="0">
												<em>None</em>
											</MenuItem>
											{this.state.months.map((option, index) => (
												<MenuItem key={index} value={option.id}>
													{option.name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={3}>
									<FormControl
										fullWidth={true}
										variant="standard"
										sx={{ minWidth: 100 }}
									>
										<InputLabel id="yearLabel">Year</InputLabel>
										<Select
											labelId="yearLabel"
											label="Year"
											value={this.state.searchYear}
											onChange={(e) => this.onChangeSearchYear(e)}
										>
											<MenuItem value="0">
												<em>None</em>
											</MenuItem>
											{this.state.years.map((option, index) => (
												<MenuItem key={index} value={option.id}>
													{option.name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={6}>
									<FormControlLabel
										control={
											<Checkbox
												checked={
													this.state.searchInclude0Balance === "1" ||
													this.state.searchInclude0Balance === 1
												}
												onChange={(e) => this.onChangeSearchInclude0Balance(e)}
											/>
										}
										label="Include 0 Balance"
									/>
								</Grid>
								{/* {this.state.companies_ready &&
									this.state.companies.length > 1 && (
										<Grid item xs={12} sm={12}>
											<Grid item xs={12} sm={6}>
												<div className="MuiFormLabel-root MuiFormLabel-colorPrimary css-u4tvz2-MuiFormLabel-root">
													Consolidate :
												</div>
											</Grid>
											<Grid container spacing={0}>
												{this.state.companies.map((option, index) => (
													<Grid item xs={12} sm={6}>
														<FormControlLabel
															control={
																<Checkbox
																	checked={this.CheckedCompanies(option.id)}
																	onChange={(e) => this.onChangeCompanies(e)}
																	style={{ padding: 3 }}
																/>
															}
															value={option.id}
															label={
																<Box component="div" fontSize={14}>
																	{option.name}
																</Box>
															}
														/>
													</Grid>
												))}
											</Grid>
										</Grid>
									)} */}
								<Grid item xs={6} sm={6}>
									<Button
										type="submit"
										variant="contained"
										startIcon={<SearchIcon />}
									>
										Search
									</Button>
								</Grid>
								<Grid item xs={6} sm={6} container justifyContent="flex-end">
									<Link
										href={
											this.state.xlsUrl +
											"?id_company=" +
											this.state.searchIdCompany +
											"&companies=" +
											this.state.searchComp +
											"&year=" +
											this.state.searchYear +
											"&month=" +
											this.state.searchMonth +
											"&include0balance=" +
											this.state.searchInclude0Balance +
											"&token=" +
											this.props.auth.user.token
										}
										underline="always"
									>
										Export
									</Link>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>

				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Typography variant="h4" gutterBottom component="div">
							Trial Balance
						</Typography>

						<TableContainer component={Paper}>
							<Table sx={{}} size="small">
								<TableHead>
									<TableRow>
										<TableCell width={5}>No.</TableCell>
										<TableCell align="center">Account</TableCell>
										<TableCell align="center">Debit</TableCell>
										<TableCell align="center">Credit</TableCell>
										<TableCell align="center">YTD Debit</TableCell>
										<TableCell align="center">YTD Credit</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{this.state.rows &&
										this.state.rows.map((row, index) => (
											<TableRow key={index}>
												<TableCell align="right">{1 + index}</TableCell>
												<TableCell>{row.name}</TableCell>
												<TableCell align="right">
													{row.normal_balance_val === "debit" && (
														<NumberFormat
															value={row.val2}
															displayType={"text"}
															thousandSeparator="."
															decimalSeparator=","
															decimalScale={2}
															fixedDecimalScale={true}
															prefix={""}
														/>
													)}
												</TableCell>
												<TableCell align="right">
													{row.normal_balance_val === "credit" && (
														<NumberFormat
															value={row.val2}
															displayType={"text"}
															thousandSeparator="."
															decimalSeparator=","
															decimalScale={2}
															fixedDecimalScale={true}
															prefix={""}
														/>
													)}
												</TableCell>
												<TableCell align="right">
													{row.normal_balance_ytd === "debit" && (
														<NumberFormat
															value={row.ytd2}
															displayType={"text"}
															thousandSeparator="."
															decimalSeparator=","
															decimalScale={2}
															fixedDecimalScale={true}
															prefix={""}
														/>
													)}
												</TableCell>
												<TableCell align="right">
													{row.normal_balance_ytd === "credit" && (
														<NumberFormat
															value={row.ytd2}
															displayType={"text"}
															thousandSeparator="."
															decimalSeparator=","
															decimalScale={2}
															fixedDecimalScale={true}
															prefix={""}
														/>
													)}
												</TableCell>
											</TableRow>
										))}

									<TableRow key={100000}>
										<TableCell></TableCell>
										<TableCell></TableCell>
										<TableCell align="right">
											<Typography
												variant="subtitle2"
												gutterBottom
												component="div"
											>
												<NumberFormat
													value={this.state.tvd}
													displayType={"text"}
													thousandSeparator="."
													decimalSeparator=","
													decimalScale={2}
													fixedDecimalScale={true}
													prefix={""}
												/>
											</Typography>
										</TableCell>
										<TableCell align="right">
											<Typography
												variant="subtitle2"
												gutterBottom
												component="div"
											>
												<NumberFormat
													value={this.state.tvc}
													displayType={"text"}
													thousandSeparator="."
													decimalSeparator=","
													decimalScale={2}
													fixedDecimalScale={true}
													prefix={""}
												/>
											</Typography>
										</TableCell>
										<TableCell align="right">
											<Typography
												variant="subtitle2"
												gutterBottom
												component="div"
											>
												<NumberFormat
													value={this.state.tyd}
													displayType={"text"}
													thousandSeparator="."
													decimalSeparator=","
													decimalScale={2}
													fixedDecimalScale={true}
													prefix={""}
												/>
											</Typography>
										</TableCell>
										<TableCell align="right">
											<Typography
												variant="subtitle2"
												gutterBottom
												component="div"
											>
												<NumberFormat
													value={this.state.tyc}
													displayType={"text"}
													thousandSeparator="."
													decimalSeparator=","
													decimalScale={2}
													fixedDecimalScale={true}
													prefix={""}
												/>
											</Typography>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>

						<Dialog
							open={this.state.error}
							onClose={this.handleClose}
							aria-labelledby="error-dialog-title"
							aria-describedby="error-dialog-description"
						>
							<DialogTitle id="error-dialog-title">Failed</DialogTitle>
							<DialogContent>
								<DialogContentText id="error-dialog-description">
									{this.state.message}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleClose} autoFocus>
									Close
								</Button>
							</DialogActions>
						</Dialog>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		common: state.common,
		loading: state.loading,
	};
};

export default connect(mapStateToProps, {
	setModule,
	setMonth,
	setYear,
	showLoading,
	hideLoading,
})(TrialBalanceReport);
