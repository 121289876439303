import http_fas from "../http-fas";

class JournalDataService {
	getAll(params) {
		return http_fas.get("/journals", { params });
	}

	get(id) {
		return http_fas.get(`/journals/${id}`);
	}

	create(data) {
		return http_fas.post("/savejournal", data);
	}

	delete(id) {
		return http_fas.delete(`/removejournal/${id}`);
	}

	code(id) {
		return http_fas.get(`/journalcode/${id}`);
	}

	getAccounts(id_company) {
		return http_fas.get(`master/account?tipe=journal&id_company=${id_company}`);
	}

	getJobs() {
		return http_fas.get(`master/job`);
	}

	getTaxes() {
		return http_fas.get(`master/tax`);
	}
}

const dataService = new JournalDataService();
export default dataService;
