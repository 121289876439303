import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import TextField from "@mui/material/TextField";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { createAccount } from "../../actions/account";
import AccountDataService from "../../services/account.service";
import Autocomplete from "@mui/material/Autocomplete";
import { AcIndexByValue } from "../../helpers/common";
import NumberFormat from "react-number-format";
import { parseNumber } from "../../helpers/common";

export default function FormAccount(props) {
	const [idType, setIdType] = useState(0);
	const [idParent, setIdParent] = useState(0);
	const [code, setCode] = useState("");
	const [name, setName] = useState("");
	const [header, setHeader] = useState(false);
	const [openingBalance, setOpeningBalance] = useState(0);

	const [accountTypes_ready, setAccountTypes_ready] = useState(false);
	const [parents_ready, setParents_ready] = useState(false);

	const [accountTypes, setAccountTypes] = useState([]);
	const [parents, setParents] = useState([]);

	const dispatch = useDispatch();

	useEffect(() => {
		AccountDataService.getType().then((response) => {
			setAccountTypes_ready(true);
			setAccountTypes(response.data.Row);
		});

		AccountDataService.getParent().then((response) => {
			setParents_ready(true);
			setParents(response.data.Row);
		});
	}, [dispatch]);

	const saveAccount = (e) => {
		dispatch(
			createAccount(
				0,
				props.idCompany,
				idParent,
				idType,
				header,
				code,
				name,
				"",
				0,
				0,
				"",
				"",
				parseNumber(openingBalance),
				parseNumber(props.idPeriod)
			)
		)
			.then((data) => {
				setIdParent(0);
				setIdType(0);
				setHeader(false);
				setCode("");
				setName("");
				setOpeningBalance(0);

				props.setNewAccount(data.Row.id);
			})
			.catch((e) => {
				props.showError(e.response.data.error);
			});
	};

	return (
		<Dialog open={props.show} onClose={(e) => props.formAccountClose(e)}>
			<DialogTitle>Insert New Account</DialogTitle>
			<DialogContent>
				<Box>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={4}>
							<FormControl>
								<FormLabel>Header</FormLabel>
								<RadioGroup row>
									<FormControlLabel
										control={<Radio />}
										label="Yes"
										value={true}
										checked={
											header === "true" || header === true
										}
										onChange={(e) =>
											setHeader(e.target.value)
										}
									/>

									<FormControlLabel
										control={<Radio />}
										label="No"
										value={false}
										checked={
											header === "false" ||
											header === false
										}
										onChange={(e) =>
											setHeader(e.target.value)
										}
									/>
								</RadioGroup>
							</FormControl>
						</Grid>

						<Grid item xs={12} sm={8}>
							{accountTypes_ready && (
								<FormControl
									fullWidth={true}
									variant="standard"
									sx={{ minWidth: 100 }}
								>
									<InputLabel id="idType">Type</InputLabel>
									<Select
										labelId="idType"
										label="Type"
										value={idType}
										onChange={(e) =>
											setIdType(e.target.value)
										}
									>
										<MenuItem value="0">
											<em>None</em>
										</MenuItem>
										{accountTypes.map((option, index) => (
											<MenuItem
												key={index}
												value={option.id}
											>
												{option.groups} - {option.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							)}
						</Grid>

						<Grid item xs={12} sm={12}>
							{parents_ready && (
								<Autocomplete
									options={parents}
									getOptionLabel={(option) => option.name}
									fullWidth={true}
									onChange={(e, val) => setIdParent(val.id)}
									value={AcIndexByValue(parents, idParent)}
									sx={{ minWidth: 100 }}
									renderInput={(params) => (
										<TextField
											variant="standard"
											{...params}
											label="Parent"
										/>
									)}
								/>
							)}
						</Grid>

						<Grid item xs={12} sm={4}>
							<TextField
								required
								label="Code"
								value={code}
								onChange={(e) => setCode(e.target.value)}
								fullWidth
								variant="standard"
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								required
								label="Name"
								value={name}
								onChange={(e) => setName(e.target.value)}
								fullWidth
								variant="standard"
							/>
						</Grid>

						<Grid item xs={12} sm={4}>
							<FormControl
								fullWidth={true}
								variant="standard"
								sx={{ minWidth: 100 }}
							>
								<NumberFormat
									customInput={TextField}
									label="Opening Balance"
									value={openingBalance}
									fullWidth
									variant="standard"
									thousandSeparator="."
									decimalSeparator=","
									decimalScale={2}
									fixedDecimalScale={true}
									autoComplete="off"
									onBlur={(e) =>
										setOpeningBalance(e.target.value)
									}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											setOpeningBalance(e.target.value);
										}
									}}
								/>
							</FormControl>
						</Grid>
					</Grid>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={(e) => props.formAccountClose(e)}>
					Cancel
				</Button>
				<Button onClick={(e) => saveAccount(e)}>Save</Button>
			</DialogActions>
		</Dialog>
	);
}
