import http_fas from "../http-fas";

class TransferMoneyDataService {
	getAll(params) {
		return http_fas.get("/transfermoney", { params });
	}

	get(id) {
		return http_fas.get(`/transfermoney/${id}`);
	}

	create(data) {
		return http_fas.post("/savetransfermoney", data);
	}

	delete(id) {
		return http_fas.delete(`/removetransfermoney/${id}`);
	}

	code(id) {
		return http_fas.get(`/transfermoneycode/${id}`);
	}

	getAccounts(id_company) {
		return http_fas.get(
			`master/account?tipe=transfer_money&id_company=${id_company}`
		);
	}

	getCustomerReceipts() {
		return http_fas.get(`bankcustomerreceipts`);
	}

	getPayingBills() {
		return http_fas.get(`bankpayingbills`);
	}
}

const dataService = new TransferMoneyDataService();
export default dataService;
