import React, { Component } from "react";
import { connect } from "react-redux";
import { setModule, setDateFrom, setDateTo } from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";

import ReportDataService from "../../services/reports.service";
import CardDataService from "../../services/card.service";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
//import TextField from "@mui/material/TextField";

import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { format } from "date-fns";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// import { parseDate } from "../../helpers/date";
import NumberFormat from "react-number-format";
// import { AllowCompany } from "../../helpers/common";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";

class profitLossYTDConsolidateReport extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		let monthFrom = format(new Date(), "M");
		//let monthFrom = 3;
		let monthTo = format(new Date(), "M");
		//let monthTo = 4;
		let yearFrom = format(new Date(), "yyyy");
		let yearTo = format(new Date(), "yyyy");

		this.state = {
			searchMonthFrom: monthFrom,
			searchMonthTo: monthTo,
			searchYearFrom: yearFrom,
			searchYearTo: yearTo,
			searchComp: "",
			//searchComp: "8,34",
			searchInclude0Balance: "0",
			rows: [],
			parents: [],
			error: false,
			message: false,

			companies_ready: false,
			companies: [],
			xlsUrl: "https://fasreport.raywhite.co.id/profitlossytdconsolidate",
			months: ReportDataService.getMonths(2),
			years: ReportDataService.getYears(),
			monthNames: [],
			companyNames: [],
			accountNames: [],
			profitLoss: [],
			totalTypePC: [],
			totalAccountPeriod: [],
			totalRight: [],
			totalGroupMonth: [],
			totalGroup: [],
			operatingProfit: [],
			netProfitLoss: [],
			tapo: [],
			tapn: [],
			totalO: 0,
			totalN: 0,
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.props.setModule("Profit Loss Year to Date Consolidate");

			this.getRows();
			this.loadSelect();
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	getRows = () => {
		this.props.showLoading();

		var params = {
			id_company: 0,
			companies: this.state.searchComp,
			month_from: this.state.searchMonthFrom,
			month_to: this.state.searchMonthTo,
			year_from: this.state.searchYearFrom,
			year_to: this.state.searchYearTo,
			include0balance: this.state.searchInclude0Balance.toString(),
		};

		ReportDataService.getProfitLossYTDConsolidate(params)
			.then((response) => {
				this.setState({
					monthNames: response.data.month_names,
					companyNames: response.data.company_names,
					accountNames: response.data.account_names,
					profitLoss: response.data.profit_loss,
					totalTypePC: response.data.total_type_pc,
					totalAccountPeriod: response.data.total_account_period,
					totalRight: response.data.total_right,
					totalGroupMonth: response.data.total_group_month,
					totalGroup: response.data.total_group,
					operatingProfit: response.data.operating_profit,
					netProfitLoss: response.data.net_profit_loss,
					tapo: response.data.tapo,
					tapn: response.data.tapn,
					totalO: response.data.total_o,
					totalN: response.data.total_n,
					message: false,
				});
				this.props.hideLoading();
			})
			.catch((e) => {
				console.log(e);
				this.setState({
					error: true,
					//message: e.response.data.error,
				});
				this.props.hideLoading();
			});
	};

	loadSelect() {
		CardDataService.getCompanies().then((response) => {
			this.setState({
				companies_ready: true,
				companies: response.data.Row,
			});
		});
	}

	onChangeSearchMonthFrom(e) {
		this.setState({ searchMonthFrom: e.target.value });
	}
	onChangeSearchMonthTo(e) {
		this.setState({ searchMonthTo: e.target.value });
	}
	onChangeSearchYearFrom(e) {
		this.setState({ searchYearFrom: e.target.value });
	}
	onChangeSearchYearTo(e) {
		this.setState({ searchYearTo: e.target.value });
	}
	onChangeSearchInclude0Balance(e) {
		let checked = "0";
		if (e.target.checked) {
			checked = "1";
		}
		this.setState({ searchInclude0Balance: checked });
	}

	searchData = (e) => {
		e.preventDefault();
		this.getRows();
	};

	handleClose = (e) => {
		this.setState({
			error: false,
		});
	};

	CheckedCompanies(id) {
		const cs = this.state.searchComp.split(",");
		return cs.includes(id.toString());
	}

	onChangeCompanies(e) {
		let cs = this.state.searchComp.split(",");
		let val = e.target.value.toString();

		if (e.target.checked) {
			if (!cs.includes(val)) {
				cs.push(val);
			}
		} else {
			let i = cs.indexOf(val);
			if (i !== -1) {
				cs.splice(i, 1);
			}
		}

		this.setState({ searchComp: cs.join(",").replace(/^,/, "") });
	}

	rowHeader1(monthNames, companyNames) {
		let s = [];

		s.push(
			<TableCell rowSpan={2} align="center">
				Account
			</TableCell>
		);

		for (let i = 0; i < monthNames.length; i++) {
			s.push(
				<TableCell colSpan={companyNames.length} align="center">
					{monthNames[i]}
				</TableCell>
			);
		}

		for (let i = 0; i < monthNames.length; i++) {
			s.push(
				<TableCell rowSpan={2} align="center">
					{monthNames[i]}
				</TableCell>
			);
		}

		s.push(
			<TableCell rowSpan={2} align="center">
				Total
			</TableCell>
		);

		return s;
	}

	rowHeader2(monthNames, cn) {
		let s = [];
		let i = 0;
		let i2 = 0;

		for (i = 0; i < monthNames.length; i++) {
			for (i2 = 0; i2 < cn.length; i2++) {
				s.push(
					<TableCell sx={{ borderLeft: "1" }} align="center">
						{cn[i2]}
					</TableCell>
				);
			}
		}

		return s;
	}

	rowPL(
		monthNames,
		companyNames,
		accountNames,
		profitLoss,
		totalTypePC,
		totalAccountPeriod,
		totalRight,
		totalGroupMonth,
		totalGroup
	) {
		let s = [];
		let i = 0;
		let i2 = 0;

		let colspan =
			monthNames.length * companyNames.length + monthNames.length + 2;

		if (accountNames) {
			for (i = 0; i < accountNames.length; i++) {
				s.push(
					<TableRow>
						<TableCell
							colSpan={colspan}
							sx={{ borderBottom: "none" }}
							className="tblBold"
						>
							{accountNames[i].name}
						</TableCell>
					</TableRow>
				);

				for (i2 = 0; i2 < accountNames[i].data.length; i2++) {
					s.push(
						<TableRow>
							<TableCell sx={{ borderBottom: "none" }}>
								{accountNames[i].data[i2].name}
							</TableCell>

							{this.selectCell(
								profitLoss,
								accountNames[i].data[i2].number,
								accountNames[i].name,
								monthNames,
								companyNames
							)}

							{this.selectCellTotalRight(
								totalAccountPeriod,
								accountNames[i].data[i2].number,
								monthNames,
								companyNames,
								totalRight
							)}
						</TableRow>
					);
				}

				s.push(
					<TableRow>
						<TableCell className="tblBold">
							Total {accountNames[i].name}
						</TableCell>

						{this.selectCellTotal(
							accountNames[i].name,
							totalTypePC,
							monthNames,
							companyNames,
							totalGroupMonth,
							totalGroup
						)}

						<TableCell colSpan={monthNames.length + 1}></TableCell>
					</TableRow>
				);
			}
		}

		return s;
	}

	rowOP(name, monthNames, companyNames, rows, totalMonth, total) {
		let s = [];
		let i = 0;
		let i2 = 0;
		let i3 = 0;
		let i4 = 0;

		if (rows) {
			s.push(
				<TableCell className="tblBold">
					<Typography variant="h6" gutterBottom component="div">
						{name}
					</Typography>
				</TableCell>
			);

			for (i = 0; i < monthNames.length; i++) {
				for (i2 = 0; i2 < companyNames.length; i2++) {
					for (i3 = 0; i3 < rows.length; i3++) {
						if (rows[i3].name === monthNames[i]) {
							for (i4 = 0; i4 < rows[i3].companies.length; i4++) {
								if (rows[i3].companies[i4].name === companyNames[i2]) {
									s.push(
										<TableCell className="tblBold" align="right">
											<Typography variant="h6" gutterBottom component="div">
												<NumberFormat
													value={rows[i3].companies[i4].data.selected_periode}
													displayType={"text"}
													thousandSeparator="."
													decimalSeparator=","
													decimalScale={2}
													fixedDecimalScale={true}
													prefix={""}
												/>
											</Typography>
										</TableCell>
									);
									break;
								}
							}

							break;
						}
					}
				}
			}

			for (i = 0; i < monthNames.length; i++) {
				for (i2 = 0; i2 < totalMonth.length; i2++) {
					if (totalMonth[i2].period === monthNames[i]) {
						s.push(
							<TableCell className="tblBold" align="right">
								<Typography variant="h6" gutterBottom component="div">
									<NumberFormat
										value={totalMonth[i2].value}
										displayType={"text"}
										thousandSeparator="."
										decimalSeparator=","
										decimalScale={2}
										fixedDecimalScale={true}
										prefix={""}
									/>
								</Typography>
							</TableCell>
						);
						break;
					}
				}
			}

			s.push(
				<TableCell className="tblBold" align="right">
					<Typography variant="h6" gutterBottom component="div">
						<NumberFormat
							value={total}
							displayType={"text"}
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							fixedDecimalScale={true}
							prefix={""}
						/>
					</Typography>
				</TableCell>
			);
		}

		return s;
	}

	selectCell(profitLoss, number, group, monthNames, companyNames) {
		let s = [];
		let i = 0;
		let i2 = 0;

		for (i = 0; i < monthNames.length; i++) {
			for (i2 = 0; i2 < companyNames.length; i2++) {
				s.push(
					<TableCell sx={{ borderBottom: "none" }} align="right">
						{this.Cell(
							profitLoss,
							group,
							number,
							monthNames[i],
							companyNames[i2]
						)}
					</TableCell>
				);
			}
		}

		return s;
	}

	selectCellTotalRight(
		totalAccountPeriod,
		number,
		monthNames,
		companyNames,
		totalRight
	) {
		let s = [];
		let i = 0;
		let i2 = 0;
		let i3 = 0;

		for (i = 0; i < monthNames.length; i++) {
			for (i2 = 0; i2 < totalAccountPeriod.length; i2++) {
				if (totalAccountPeriod[i2].number === number) {
					for (i3 = 0; i3 < totalAccountPeriod[i2].data.length; i3++) {
						if (totalAccountPeriod[i2].data[i3].period === monthNames[i]) {
							s.push(
								<TableCell
									sx={{ borderBottom: "none" }}
									align="right"
									className="tblBold"
								>
									<NumberFormat
										value={totalAccountPeriod[i2].data[i3].value}
										displayType={"text"}
										thousandSeparator="."
										decimalSeparator=","
										decimalScale={2}
										fixedDecimalScale={true}
										prefix={""}
									/>
								</TableCell>
							);

							break;
						}
					}
					break;
				}
			}
		}

		for (i in totalRight) {
			if (parseInt(i) === number) {
				s.push(
					<TableCell
						sx={{ borderBottom: "none" }}
						align="right"
						className="tblBold"
					>
						<NumberFormat
							value={totalRight[i]}
							displayType={"text"}
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							fixedDecimalScale={true}
							prefix={""}
						/>
					</TableCell>
				);
			}
		}

		return s;
	}

	selectCellTotal(
		name,
		totalTypePC,
		monthNames,
		companyNames,
		totalGroupMonth,
		totalGroup
	) {
		let s = [];
		let i = 0;
		let i2 = 0;
		let i3 = 0;
		let i4 = 0;
		let i5 = 0;

		for (i = 0; i < monthNames.length; i++) {
			for (i2 = 0; i2 < companyNames.length; i2++) {
				for (i3 = 0; i3 < totalTypePC.length; i3++) {
					if (totalTypePC[i3].name === name) {
						for (i4 = 0; i4 < totalTypePC[i3].data.length; i4++) {
							if (totalTypePC[i3].data[i4].name === monthNames[i]) {
								for (i5 = 0; i5 < totalTypePC[i3].data[i4].data.length; i5++) {
									if (
										totalTypePC[i3].data[i4].data[i5].name === companyNames[i2]
									) {
										s.push(
											<TableCell align="right" className="tblBold">
												<NumberFormat
													value={totalTypePC[i3].data[i4].data[i5].value}
													displayType={"text"}
													thousandSeparator="."
													decimalSeparator=","
													decimalScale={2}
													fixedDecimalScale={true}
													prefix={""}
												/>
											</TableCell>
										);
										break;
									}
								}

								break;
							}
						}
						break;
					}
				}
			}
		}

		for (i = 0; i < monthNames.length; i++) {
			for (i2 in totalGroupMonth) {
				if (i2 === name) {
					for (i3 in totalGroupMonth[i2]) {
						if (monthNames[i] === i3) {
							s.push(
								<TableCell align="right" className="tblBold">
									<NumberFormat
										value={totalGroupMonth[i2][i3]}
										displayType={"text"}
										thousandSeparator="."
										decimalSeparator=","
										decimalScale={2}
										fixedDecimalScale={true}
										prefix={""}
									/>
								</TableCell>
							);
							break;
						}
					}
					break;
				}
			}
		}

		for (i in totalGroup) {
			if (i === name) {
				s.push(
					<TableCell align="right" className="tblBold">
						<NumberFormat
							value={totalGroup[i]}
							displayType={"text"}
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							fixedDecimalScale={true}
							prefix={""}
						/>
					</TableCell>
				);

				break;
			}
		}

		return s;
	}

	Cell(profitLoss, group, number, monthName, companyName) {
		let s = [];
		let i = 0;
		let i2 = 0;
		let i3 = 0;
		let i4 = 0;

		for (i = 0; i < profitLoss.length; i++) {
			if (profitLoss[i].name === group) {
				for (i2 = 0; i2 < profitLoss[i].ids.length; i2++) {
					if (profitLoss[i].ids[i2].name === number) {
						for (i3 = 0; i3 < profitLoss[i].ids[i2].periode.length; i3++) {
							if (profitLoss[i].ids[i2].periode[i3].name === monthName) {
								for (
									i4 = 0;
									i4 < profitLoss[i].ids[i2].periode[i3].companies.length;
									i4++
								) {
									if (
										profitLoss[i].ids[i2].periode[i3].companies[i4].name ===
										companyName
									) {
										s.push(
											<NumberFormat
												value={
													profitLoss[i].ids[i2].periode[i3].companies[i4].data
														.selected_periode
												}
												displayType={"text"}
												thousandSeparator="."
												decimalSeparator=","
												decimalScale={2}
												fixedDecimalScale={true}
												prefix={""}
											/>
										);
									}
								}

								break;
							}
						}

						break;
					}
				}

				break;
			}
		}

		return s;
	}

	render() {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={(e) => this.searchData(e)}>
							<Typography variant="h5">Search Form</Typography>

							<Grid container spacing={3}>
								<Grid item xs={12} sm={6}>
									<Grid container spacing={1}>
										<Grid item xs={8}>
											<FormControl
												fullWidth={true}
												variant="standard"
												sx={{ minWidth: 100 }}
											>
												<InputLabel id="fromLabel">Month From</InputLabel>
												<Select
													labelId="fromLabel"
													label="From"
													value={this.state.searchMonthFrom}
													onChange={(e) => this.onChangeSearchMonthFrom(e)}
												>
													<MenuItem value="0">
														<em>None</em>
													</MenuItem>
													{this.state.months.map((option, index) => (
														<MenuItem key={index} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={4}>
											<FormControl
												fullWidth={true}
												variant="standard"
												sx={{ minWidth: 100 }}
											>
												<InputLabel id="yearLabel">Year From</InputLabel>
												<Select
													labelId="yearLabel"
													label="Year"
													value={this.state.searchYearFrom}
													onChange={(e) => this.onChangeSearchYearFrom(e)}
												>
													<MenuItem value="0">
														<em>None</em>
													</MenuItem>
													{this.state.years.map((option, index) => (
														<MenuItem key={index} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
									</Grid>
								</Grid>

								<Grid item xs={12} sm={6}>
									<Grid container spacing={1}>
										<Grid item xs={8}>
											<FormControl
												fullWidth={true}
												variant="standard"
												sx={{ minWidth: 100 }}
											>
												<InputLabel id="from2Label">Month To</InputLabel>
												<Select
													labelId="from2Label"
													label="To"
													value={this.state.searchMonthTo}
													onChange={(e) => this.onChangeSearchMonthTo(e)}
												>
													<MenuItem value="0">
														<em>None</em>
													</MenuItem>
													{this.state.months.map((option, index) => (
														<MenuItem key={index} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={4}>
											<FormControl
												fullWidth={true}
												variant="standard"
												sx={{ minWidth: 100 }}
											>
												<InputLabel id="year2Label">Year To</InputLabel>
												<Select
													labelId="year2Label"
													label="Year"
													value={this.state.searchYearTo}
													onChange={(e) => this.onChangeSearchYearTo(e)}
												>
													<MenuItem value="0">
														<em>None</em>
													</MenuItem>
													{this.state.years.map((option, index) => (
														<MenuItem key={index} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
									</Grid>
								</Grid>

								<Grid item xs={12} sm={12}>
									<FormControlLabel
										control={
											<Checkbox
												checked={
													this.state.searchInclude0Balance === "1" ||
													this.state.searchInclude0Balance === 1
												}
												onChange={(e) => this.onChangeSearchInclude0Balance(e)}
											/>
										}
										label="Include 0 Balance"
									/>
								</Grid>
								{this.state.companies_ready &&
									this.state.companies.length > 1 && (
										<Grid item xs={12} sm={12}>
											<Grid item xs={12} sm={6}>
												<div className="MuiFormLabel-root MuiFormLabel-colorPrimary css-u4tvz2-MuiFormLabel-root">
													Consolidate :
												</div>
											</Grid>
											<Grid container spacing={0}>
												{this.state.companies.map((option, index) => (
													<Grid item xs={12} sm={6}>
														<FormControlLabel
															control={
																<Checkbox
																	checked={this.CheckedCompanies(option.id)}
																	onChange={(e) => this.onChangeCompanies(e)}
																	style={{ padding: 3 }}
																/>
															}
															value={option.id}
															label={
																<Box component="div" fontSize={14}>
																	{option.name}
																</Box>
															}
														/>
													</Grid>
												))}
											</Grid>
										</Grid>
									)}

								<Grid item xs={6} sm={6}>
									<Button
										type="submit"
										variant="contained"
										startIcon={<SearchIcon />}
									>
										Search
									</Button>
								</Grid>
								<Grid item xs={6} sm={6} container justifyContent="flex-end">
									<Link
										href={
											this.state.xlsUrl +
											"?id_company=0" +
											"&companies=" +
											this.state.searchComp +
											"&month_from=" +
											this.state.searchMonthFrom +
											"&month_to=" +
											this.state.searchMonthTo +
											"&year_from=" +
											this.state.searchYearFrom +
											"&year_to=" +
											this.state.searchYearTo +
											"&include0balance=" +
											this.state.searchInclude0Balance +
											"&token=" +
											this.props.auth.user.token
										}
										underline="always"
									>
										Export
									</Link>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>

				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Typography variant="h4" gutterBottom component="div">
							Profit Loss Year to Date (Consolidate)
						</Typography>

						<TableContainer component={Paper}>
							<Table sx={{}} size="small">
								<TableHead>
									<TableRow>
										{this.rowHeader1(
											this.state.monthNames,
											this.state.companyNames
										)}
									</TableRow>
									<TableRow>
										{this.rowHeader2(
											this.state.monthNames,
											this.state.companyNames
										)}
									</TableRow>
								</TableHead>

								<TableBody>
									{this.rowPL(
										this.state.monthNames,
										this.state.companyNames,
										this.state.accountNames,
										this.state.profitLoss,
										this.state.totalTypePC,
										this.state.totalAccountPeriod,
										this.state.totalRight,
										this.state.totalGroupMonth,
										this.state.totalGroup
									)}
									<TableRow>
										{this.rowOP(
											"Operating Profit",
											this.state.monthNames,
											this.state.companyNames,
											this.state.operatingProfit,
											this.state.tapo,
											this.state.totalO
										)}
									</TableRow>
									<TableRow>
										{this.rowOP(
											"Net Profit / (Loss)",
											this.state.monthNames,
											this.state.companyNames,
											this.state.netProfitLoss,
											this.state.tapn,
											this.state.totalN
										)}
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>

						<Dialog
							open={this.state.error}
							onClose={this.handleClose}
							aria-labelledby="error-dialog-title"
							aria-describedby="error-dialog-description"
						>
							<DialogTitle id="error-dialog-title">Failed</DialogTitle>
							<DialogContent>
								<DialogContentText id="error-dialog-description">
									{this.state.message}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleClose} autoFocus>
									Close
								</Button>
							</DialogActions>
						</Dialog>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		common: state.common,
		loading: state.loading,
	};
};

export default connect(mapStateToProps, {
	setModule,
	setDateFrom,
	setDateTo,
	showLoading,
	hideLoading,
})(profitLossYTDConsolidateReport);
