import http from "../http-common";
import httpm from "../http-multipart";

class PostDataService {
	getAll(params) {
		return http.get("/posts", { params });
	}

	get(id) {
		return http.get(`/posts/${id}`);
	}

	create(data) {
		return httpm.post("/posts", data);
	}

	delete(id) {
		return http.delete(`/posts/${id}`);
	}
}

const dataService = new PostDataService();
export default dataService;
