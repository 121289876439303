import React, { Component } from "react";
import { connect } from "react-redux";
import { closePeriod } from "../../actions/company";
import { setModule } from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";

import TextField from "@mui/material/TextField";
import { format } from "date-fns";
import SaveIcon from "@mui/icons-material/Save";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CardDataService from "../../services/card.service";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

class ClosePeriod extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			id: 0,
			financial_start: format(new Date(), "yyyy-01-01"),
			financial_end: format(new Date(), "yyyy-12-31"),
			companies_ready: false,
			companies: [],

			error: false,
			message: false,
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.props.setModule("Close Period");

			this.loadSelect();
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	loadSelect() {
		CardDataService.getCompanies().then((response) => {
			this.setState({
				companies_ready: true,
				companies: response.data.Row,
			});
		});
	}

	onChangeIdCompany(e) {
		this.setState({ id: e.target.value });
	}
	onChangeFinancialStart(e) {
		this.setState({ financial_start: e.target.value });
	}
	onChangeFinancialEnd(e) {
		this.setState({ financial_end: e.target.value });
	}

	alertClose = () => {
		this.setState({ error: false });
	};

	saveClosePeriod(e) {
		e.preventDefault();
		this.props.showLoading();

		this.setState({
			message: false,
		});

		this.props
			.closePeriod(
				this.state.id,
				this.state.financial_start,
				this.state.financial_end
			)
			.then((data) => {
				this.props.history.push("/company/form");
			})
			.catch((e) => {
				this.setState({
					message: e.response.data.error,
					error: true,
				});
				this.props.hideLoading();
			});
	}

	render() {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={(e) => this.saveClosePeriod(e)}>
							<Typography variant="h5">Company Form</Typography>

							<Grid container spacing={3}>
								<Grid item xs={12} sm={12}>
									<Dialog
										open={this.state.error}
										onClose={this.alertClose}
										aria-labelledby="error-dialog-title"
										aria-describedby="error-dialog-description"
									>
										<DialogTitle id="error-dialog-title">Failed</DialogTitle>
										<DialogContent>
											<DialogContentText id="error-dialog-description">
												{this.state.message}
											</DialogContentText>
										</DialogContent>
										<DialogActions>
											<Button onClick={this.alertClose} autoFocus>
												Close
											</Button>
										</DialogActions>
									</Dialog>
								</Grid>

								<Grid item xs={12} sm={6}>
									{this.state.companies_ready && (
										<FormControl
											fullWidth={true}
											variant="standard"
											sx={{ minWidth: 100 }}
										>
											<InputLabel id="idCompany">Company</InputLabel>
											<Select
												labelId="idCompany"
												label="Company"
												value={this.state.id_company}
												onChange={(e) => this.onChangeIdCompany(e)}
											>
												<MenuItem value="0">
													<em>None</em>
												</MenuItem>
												{this.state.companies.map((option, index) => (
													<MenuItem key={index} value={option.id}>
														{option.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									)}
								</Grid>

								<Grid item xs={12} sm={3}>
									<TextField
										label="Financial Start Date"
										type="date"
										value={this.state.financial_start}
										onChange={(e) => this.onChangeFinancialStart(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={3}>
									<TextField
										label="Financial End Date"
										type="date"
										value={this.state.financial_end}
										onChange={(e) => this.onChangeFinancialEnd(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={12}>
									<Button
										type="submit"
										variant="contained"
										disabled={this.props.loading.loading}
										startIcon={<SaveIcon />}
									>
										{this.props.loading.loading && (
											<span>
												<span className="spinner-border spinner-border-sm"></span>
												&nbsp;
											</span>
										)}
										<span>
											{" "}
											{this.props.loading.loading ? "Please Wait" : "Save"}
										</span>
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		loading: state.loading,
	};
};

export default connect(mapStateToProps, {
	closePeriod,
	showLoading,
	hideLoading,
	setModule,
})(ClosePeriod);
