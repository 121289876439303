import React, { Component } from "react";
import { connect } from "react-redux";
import { setModule, setLook, setPeriod } from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";

import ReportDataService from "../../services/reports.service";
import CardDataService from "../../services/card.service";
import TransferMoneyDataService from "../../services/transfer_money.service";
import AccountDataService from "../../services/account.service";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { parseDate } from "../../helpers/date";
import NumberFormat from "react-number-format";

import Link from "@mui/material/Link";
import Autocomplete from "@mui/material/Autocomplete";
import { AcIndexByValue, AllowCompany } from "../../helpers/common";

class CashFlowAnalysisReport extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		let look;

		if (this.props.common.look) {
			look = this.props.common.look;
		} else {
			look = 30;
		}

		let period;

		if (this.props.common.period) {
			period = this.props.common.period;
		} else {
			period = this.props.auth.user.id_period;
		}

		this.state = {
			searchLook: look,
			searchIdPeriod: period,
			searchIdAccount: 0,
			searchIdCompany: this.props.auth.idCompany,
			searchComp: "",
			currentBalance: "",
			CurrentDate: "",

			rows: [],
			error: false,
			message: false,

			companies_ready: false,
			accounts_ready: false,
			periods_ready: false,
			companies: [],
			accounts: [],
			periods: [],
			xlsUrl: "https://fasreport.raywhite.co.id/cashflowanalysis",
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.props.setModule("Cash Flow Analysis");

			TransferMoneyDataService.getCustomerReceipts()
				.then((res) => {
					this.setState({ searchIdAccount: res.data }, function () {
						if (this.state.searchIdAccount > 0) {
							this.getRows();
						}
						this.loadSelect();
					});
				})
				.catch((e) => {
					this.setState({
						error: true,
						message: e.response.data.error,
					});
					this.props.hideLoading();
				});
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	getRows = () => {
		this.props.showLoading();
		this.props.setLook(this.state.searchLook);
		this.props.setPeriod(this.state.searchIdPeriod);

		var params = {
			id_company: this.state.searchIdCompany,
			companies: this.state.searchComp,
			look: this.state.searchLook,
			account: this.state.searchIdAccount,
			id_period: this.state.searchIdPeriod,
		};

		this.setState({ message: false }, function () {
			ReportDataService.getCashFlowAnalysis(params)
				.then((response) => {
					this.setState({
						rows: response.data.group,
						currentBalance: response.data.current_balance,
						CurrentDate: response.data.current_date,
					});
					this.props.hideLoading();
				})
				.catch((e) => {
					this.setState({
						error: true,
						message: e.response.data.error,
					});
					this.props.hideLoading();
				});
		});

		this.props.hideLoading();
	};

	loadSelect() {
		CardDataService.getCompanies().then((response) => {
			this.setState({
				companies_ready: true,
				companies: response.data.Row,
			});
		});

		TransferMoneyDataService.getAccounts(this.state.searchIdCompany).then(
			(response) => {
				this.setState({
					accounts_ready: true,
					accounts: response.data.Row,
				});
			}
		);
		AccountDataService.getPeriod({
			id_company: this.state.searchIdCompany,
		}).then((response) => {
			this.setState({
				periods_ready: true,
				periods: response.data.Row,
			});
		});
	}

	onChangeSearchIdCompany(e, val) {
		if (val !== null) {
			this.setState({ searchIdCompany: val.id }, function () {
				AccountDataService.getPeriod({ id_company: val.id }).then(
					(response) => {
						this.setState(
							{
								periods: response.data.Row,
								searchIdPeriod: response.data.Row[0].id,
							},
							function () {
								TransferMoneyDataService.getAccounts(
									this.state.searchIdCompany
								).then((response) => {
									this.setState({
										accounts: response.data.Row,
										searchIdAccount: 0,
									});
								});
							}
						);
					}
				);
			});
		} else {
			this.setState({ searchIdCompany: 0 });
		}
	}
	onChangeIdperiod(e) {
		this.setState({ searchIdPeriod: e.target.value });
	}
	onChangeSearchLook(e) {
		this.setState({ searchLook: e.target.value });
	}
	onChangeSearchIdAccount(e, val) {
		if (val !== null) {
			this.setState({ searchIdAccount: val.id });
		} else {
			this.setState({ searchIdAccount: 0 });
		}
	}

	searchData = (e) => {
		e.preventDefault();
		this.getRows();
	};

	handleClose = (e) => {
		this.setState({
			error: false,
		});
	};

	CheckedCompanies(id) {
		const cs = this.state.searchComp.split(",");
		return cs.includes(id.toString());
	}

	onChangeCompanies(e) {
		let cs = this.state.searchComp.split(",");
		let val = e.target.value.toString();

		if (e.target.checked) {
			if (!cs.includes(val)) {
				cs.push(val);
			}
		} else {
			let i = cs.indexOf(val);
			if (i !== -1) {
				cs.splice(i, 1);
			}
		}

		this.setState({ searchComp: cs.join(",").replace(/^,/, "") });
	}

	tableChild(rows) {
		if (rows == null) {
			return;
		}
		return rows.map((row, i) =>
			row.data.map((row2, i2) => (
				<>
					<TableRow key={0}>
						<TableCell align="center">
							{i2 === 0 && parseDate(row.due_date)}
						</TableCell>
						<TableCell>
							{row2.code} - {row2.card}
						</TableCell>
						<TableCell align="right">
							<NumberFormat
								value={row2.outstanding}
								displayType={"text"}
								thousandSeparator="."
								decimalSeparator=","
								decimalScale={2}
								fixedDecimalScale={true}
								prefix={""}
							/>
						</TableCell>
						<TableCell align="right">
							<NumberFormat
								value={row2.cash_available}
								displayType={"text"}
								thousandSeparator="."
								decimalSeparator=","
								decimalScale={2}
								fixedDecimalScale={true}
								prefix={""}
							/>
						</TableCell>
					</TableRow>
				</>
			))
		);
	}

	render() {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={(e) => this.searchData(e)}>
							<Typography variant="h5">Search Form</Typography>

							<Grid container spacing={3}>
								{AllowCompany(this.props.auth.user.usergroup) && (
									<Grid item xs={12} sm={12}>
										{this.state.companies_ready && (
											<Autocomplete
												options={this.state.companies}
												getOptionLabel={(option) => option.name}
												fullWidth={true}
												onChange={(e, val) =>
													this.onChangeSearchIdCompany(e, val)
												}
												value={AcIndexByValue(
													this.state.companies,
													this.state.searchIdCompany
												)}
												sx={{ minWidth: 100 }}
												renderInput={(params) => (
													<TextField
														variant="standard"
														{...params}
														label="Company"
													/>
												)}
											/>
										)}
									</Grid>
								)}

								<Grid item xs={12} sm={5}>
									{this.state.accounts_ready && (
										<Autocomplete
											options={this.state.accounts}
											getOptionLabel={(option) => option.name}
											fullWidth={true}
											onChange={(e, val) =>
												this.onChangeSearchIdAccount(e, val)
											}
											value={AcIndexByValue(
												this.state.accounts,
												this.state.searchIdAccount
											)}
											sx={{ minWidth: 100 }}
											renderInput={(params) => (
												<TextField
													variant="standard"
													{...params}
													label="Bank Account"
												/>
											)}
										/>
									)}
								</Grid>
								<Grid item xs={12} sm={2}>
									<TextField
										label="Days to Look Ahead"
										value={this.state.searchLook}
										onChange={(e) => this.onChangeSearchLook(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={5}>
									{this.state.periods_ready && (
										<FormControl
											fullWidth={true}
											variant="standard"
											sx={{ minWidth: 100 }}
										>
											<InputLabel id="periodsLabel">Period</InputLabel>
											<Select
												labelId="periodsLabel"
												label="Period"
												value={this.state.searchIdPeriod}
												onChange={(e) => this.onChangeIdperiod(e)}
											>
												{this.state.periods.map((option, index) => (
													<MenuItem key={index} value={option.id}>
														{option.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									)}
								</Grid>

								<Grid item xs={12} sm={12}></Grid>

								{/* {this.state.companies_ready &&
									this.state.companies.length > 1 && (
										<Grid item xs={12} sm={12}>
											<Grid item xs={12} sm={6}>
												<div className="MuiFormLabel-root MuiFormLabel-colorPrimary css-u4tvz2-MuiFormLabel-root">
													Consolidate :
												</div>
											</Grid>
											<Grid container spacing={0}>
												{this.state.companies.map((option, index) => (
													<Grid item xs={12} sm={6}>
														<FormControlLabel
															control={
																<Checkbox
																	checked={this.CheckedCompanies(option.id)}
																	onChange={(e) => this.onChangeCompanies(e)}
																	style={{ padding: 3 }}
																/>
															}
															value={option.id}
															label={
																<Box component="div" fontSize={14}>
																	{option.name}
																</Box>
															}
														/>
													</Grid>
												))}
											</Grid>
										</Grid>
									)} */}

								<Grid item xs={6} sm={6}>
									<Button
										type="submit"
										variant="contained"
										startIcon={<SearchIcon />}
									>
										Search
									</Button>
								</Grid>
								<Grid item xs={6} sm={6} container justifyContent="flex-end">
									<Link
										href={
											this.state.xlsUrl +
											"?id_company=" +
											this.state.searchIdCompany +
											"&companies=" +
											this.state.searchComp +
											"&account=" +
											this.state.searchIdAccount +
											"&look=" +
											this.state.searchLook +
											"&id_period=" +
											this.state.searchIdPeriod +
											"&token=" +
											this.props.auth.user.token
										}
										underline="always"
									>
										Export
									</Link>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>

				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Typography variant="h4" gutterBottom component="div">
							Cash Flow Analysis
						</Typography>

						<TableContainer component={Paper}>
							<Table sx={{}} size="small">
								<TableHead>
									<TableRow>
										<TableCell align="center">Date</TableCell>
										<TableCell align="center">Description</TableCell>
										<TableCell align="center">Amount</TableCell>
										<TableCell align="center">Cash Available</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow key={0}>
										<TableCell align="center">
											{parseDate(this.state.CurrentDate)}
										</TableCell>
										<TableCell>Current Balance</TableCell>
										<TableCell align="center"></TableCell>
										<TableCell align="right">
											<NumberFormat
												value={this.state.currentBalance}
												displayType={"text"}
												thousandSeparator="."
												decimalSeparator=","
												decimalScale={2}
												fixedDecimalScale={true}
												prefix={""}
											/>
										</TableCell>
									</TableRow>
									{this.tableChild(this.state.rows)}
								</TableBody>
							</Table>
						</TableContainer>

						<Dialog
							open={this.state.error}
							onClose={this.handleClose}
							aria-labelledby="error-dialog-title"
							aria-describedby="error-dialog-description"
						>
							<DialogTitle id="error-dialog-title">Failed</DialogTitle>
							<DialogContent>
								<DialogContentText id="error-dialog-description">
									{this.state.message}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleClose} autoFocus>
									Close
								</Button>
							</DialogActions>
						</Dialog>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		common: state.common,
		loading: state.loading,
	};
};

export default connect(mapStateToProps, {
	setModule,
	setPeriod,
	setLook,
	showLoading,
	hideLoading,
})(CashFlowAnalysisReport);
