import http_fas from "../http-fas";

class TaxDataService {
	getAll(params) {
		return http_fas.get("/taxes", { params });
	}

	get(id) {
		return http_fas.get(`/taxes/${id}`);
	}

	create(data) {
		return http_fas.post("/savetax", data);
	}

	delete(id) {
		return http_fas.delete(`/removetax/${id}`);
	}

	getType() {
		return http_fas.get(`master/tax_type`);
	}

	getAccount() {
		return http_fas.get(`master/account?tipe=journal`);
	}
}

const dataService = new TaxDataService();
export default dataService;
