import http_fas from "../http-fas";

class PaymentMethodDataService {
	getAll(params) {
		return http_fas.get("/paymentmethods", { params });
	}

	get(id) {
		return http_fas.get(`/paymentmethods/${id}`);
	}

	create(data) {
		return http_fas.post("/savepaymentmethod", data);
	}

	delete(id) {
		return http_fas.delete(`/removepaymentmethod/${id}`);
	}
}

const dataService = new PaymentMethodDataService();
export default dataService;
