export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const CREATE_TUTORIAL = "CREATE_TUTORIAL";
export const RETRIEVE_TUTORIALS = "RETRIEVE_TUTORIALS";
export const UPDATE_TUTORIAL = "UPDATE_TUTORIAL";
export const DELETE_TUTORIAL = "DELETE_TUTORIAL";
export const DELETE_ALL_TUTORIALS = "DELETE_ALL_TUTORIALS";
export const CURRENT_PAGE_TUTORIALS = "CURRENT_PAGE_TUTORIALS";
export const CURRENT_PAGESIZE_TUTORIALS = "CURRENT_PAGESIZE_TUTORIALS";

export const CREATE = "CREATE";
export const RETRIEVE = "RETRIEVE";
export const DELETE = "DELETE";

export const CURRENT_PAGE = "CURRENT_PAGE";
export const CURRENT_PAGESIZE = "CURRENT_PAGESIZE";
export const CURRENT_ORDER = "CURRENT_ORDER";
export const CURRENT_ORDERBY = "CURRENT_ORDERBY";
export const CURRENT_MODULE = "CURRENT_MODULE";
export const CURRENT_DATEFROM = "CURRENT_DATEFROM";
export const CURRENT_DATETO = "CURRENT_DATETO";
export const CURRENT_MONTH = "CURRENT_MONTH";
export const CURRENT_YEAR = "CURRENT_YEAR";
export const CURRENT_LOOK = "CURRENT_LOOK";
export const CURRENT_PERIOD = "CURRENT_PERIOD";
export const CURRENT_ACCOUNT = "CURRENT_ACCOUNT";

export const CREATE_CARD = "CREATE_CARD";
export const RETRIEVE_CARD = "RETRIEVE_CARD";
export const DELETE_CARD = "DELETE_CARD";

export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const RETRIEVE_ACCOUNT = "RETRIEVE_ACCOUNT";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const UPDATE_LINKED_ACCOUNTS = "UPDATE_LINKED_ACCOUNTS";

export const CREATE_COMPANY = "CREATE_COMPANY";
export const RETRIEVE_COMPANY = "RETRIEVE_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const CLOSE_PERIOD = "CLOSE_PERIOD";

export const CREATE_JOB = "CREATE_JOB";
export const RETRIEVE_JOB = "RETRIEVE_JOB";
export const DELETE_JOB = "DELETE_JOB";

export const CREATE_TAX = "CREATE_TAX";
export const RETRIEVE_TAX = "RETRIEVE_TAX";
export const DELETE_TAX = "DELETE_TAX";

export const CREATE_USER = "CREATE_USER";
export const RETRIEVE_USER = "RETRIEVE_USER";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_PROFILE = "UPDATE_PROFILE";

export const CREATE_JOURNAL = "CREATE_JOURNAL";
export const RETRIEVE_JOURNAL = "RETRIEVE_JOURNAL";
export const DELETE_JOURNAL = "DELETE_JOURNAL";

export const CREATE_TRANSFER_MONEY = "CREATE_TRANSFER_MONEY";
export const RETRIEVE_TRANSFER_MONEY = "RETRIEVE_TRANSFER_MONEY";
export const DELETE_TRANSFER_MONEY = "DELETE_TRANSFER_MONEY";

export const CREATE_SPEND_MONEY = "CREATE_SPEND_MONEY";
export const RETRIEVE_SPEND_MONEY = "RETRIEVE_SPEND_MONEY";
export const DELETE_SPEND_MONEY = "DELETE_SPEND_MONEY";
export const RETRIEVE_BANK_REGISTER = "RETRIEVE_BANK_REGISTER";
export const RETRIEVE_SALES_REGISTER = "RETRIEVE_SALES_REGISTER";
export const RETRIEVE_PURCHASES_REGISTER = "RETRIEVE_PURCHASES_REGISTER";

export const CREATE_PAYMENT_METHOD = "CREATE_PAYMENT_METHOD";
export const RETRIEVE_PAYMENT_METHOD = "RETRIEVE_PAYMENT_METHOD";
export const DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD";

export const CREATE_RECEIVE_MONEY = "CREATE_RECEIVE_MONEY";
export const RETRIEVE_RECEIVE_MONEY = "RETRIEVE_RECEIVE_MONEY";
export const DELETE_RECEIVE_MONEY = "DELETE_RECEIVE_MONEY";

export const CREATE_ORDER = "CREATE_ORDER";
export const RETRIEVE_ORDER = "RETRIEVE_ORDER";
export const DELETE_ORDER = "DELETE_ORDER";

export const CREATE_INVOICE = "CREATE_INVOICE";
export const RETRIEVE_INVOICE = "RETRIEVE_INVOICE";
export const DELETE_INVOICE = "DELETE_INVOICE";

export const CREATE_RECEIVE_PAYMENT = "CREATE_RECEIVE_PAYMENT";
export const RETRIEVE_RECEIVE_PAYMENT = "RETRIEVE_RECEIVE_PAYMENT";
export const DELETE_RECEIVE_PAYMENT = "DELETE_RECEIVE_PAYMENT";

export const CREATE_PURCHASE_ORDER = "CREATE_PURCHASE_ORDER";
export const RETRIEVE_PURCHASE_ORDER = "RETRIEVE_PURCHASE_ORDER";
export const DELETE_PURCHASE_ORDER = "DELETE_PURCHASE_ORDER";

export const CREATE_BILL = "CREATE_BILL";
export const RETRIEVE_BILL = "RETRIEVE_BILL";
export const DELETE_BILL = "DELETE_BILL";

export const CREATE_PAYBILL = "CREATE_PAYBILL";
export const RETRIEVE_PAYBILL = "RETRIEVE_PAYBILL";
export const DELETE_PAYBILL = "DELETE_PAYBILL";
